import axios from "axios";
import { getBaseUrl } from "./../utils";
import Cookies from "js-cookie";

const category = getBaseUrl() + "api/user/category";
const subcategory = getBaseUrl() + "api/user/sub_category";
const service_request = getBaseUrl() + "api/user/service_request";

export const service_request_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    method: "post",
    url: service_request,
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const searchbycategory_api = async (data) => {
  let config = {
    method: "get",
    url: getBaseUrl() + `api/user/services?search=${data.search}`,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const filterbycategory_api = async (data) => {
  let config = {
    method: "get",
    url: getBaseUrl() + `api/user/services?categs=${data.categs}`,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const fetch_service_details = async (id) => {
  let config = {
    method: "get",
    url: getBaseUrl() + `api/user/service/${id}`,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const fetch_category = async () => {
  let config = {
    method: "get",
    url: category,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetch_sub_category = async (id) => {
  let config = {
    method: "get",
    url: `${subcategory}?cat_id=${id}`,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetch_category_services = async (data) => {
  let config1 = {
    method: "get",
    url: getBaseUrl() + `api/user/services_by_categ?scat_id=${data.sId}`,
  };
  let config2 = {
    method: "get",
    url: getBaseUrl() + `api/user/services_by_categ?cat_id=${data.cId}`,
  };
  try {
    let res = await axios(data.sId ? config1 : config2);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetch_services_by_categoryId = async (data) => {
  let config1 = {
    method: "get",
    url: getBaseUrl() + `api/user/services_by_categ?cat_id=${data}`,
  };

  try {
    let res = await axios(config1);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
