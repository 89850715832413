import React from "react";
import DataNotFound from "../../Common/DataNotFound";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";

const Help = () => {
  return (
    <div>
      <div class="contact-info-area" style={{ paddingBlock: "2rem" }}>
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="single-contact-info">
                <AiOutlineMail size={20} />
                <h3>Email Us:</h3>
                <p>
                  <a href="mailto:contact.cloudgem@cloudgem.co.in">contact.cloudgem@cloudgem.co.in</a>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-contact-info">
                <BsTelephone size={20} />
                <h3>Call Us:</h3>
                <p>
                  <a href="tel:8373912163"> Tel. +91 8373912163 </a>
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="single-contact-info">
                <i class="bx bx-location-plus"></i>
                <h3>India</h3>
                <a href="tel:7011481834">
                  <p>Tel. +91-7011481834</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
