import React from "react";
import DataNotFound from "../../Common/DataNotFound";
import { Avatar } from "@material-ui/core";
import "./Notify.css";
import { notifications_vendor_api } from "../api/vender";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
const ServiceMessages = () => {
  const [notificationArry, setnotificationArry] = useState([]);
  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    getnotifucationfun();
  }, []);
  const getnotifucationfun = async () => {
    setisloading(true);

    try {
      const res = await notifications_vendor_api("booking");
      if (res.data.status) {
        setnotificationArry(res.data.data.inbox);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };
  return (
    <div>
      <section class="section">
        <div class="container">
          <h3 class="m-b-50 heading-line">Service Notifications ({notificationArry?.length})</h3>

          <div class="notification-ui_dd-content">
            {notificationArry.length > 0 ? (
              notificationArry.map((data, index) => (
                <div class={`notification-list  ${data.opened ? "notification-list--unread" : ""}`}>
                  <div class="notification-list_content">
                    <div class="notification-list_img">
                      <Avatar
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "6px",
                        }}
                      />
                    </div>
                    <div class="notification-list_detail">
                      <p>{data?.title}</p>
                      <p class="text-muted">{data?.text}</p>
                      <p class="text-muted">
                        <small>{data?.created_at}</small>
                      </p>
                    </div>
                  </div>
                  <div class="notification-list_feature-img" style={{ cursor: "pointer" }}>
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </div>
                </div>
              ))
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceMessages;
