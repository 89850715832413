import React from "react";
import s from "./MoreiBuzoo.module.css";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
const About = () => {
  return (
    <>
      <div className={s["about-container"]}>
        <BreadcrumbSection tittle="About" />
        <div className={s["about-title"]}>
          <h1>About Us</h1>
        </div>
        <div className="about-layout pb-5">
          <p>
            we are more than just a technology platform to provide industrial services—we are a catalyst for innovation and efficiency in the manufacturing
            industry. As a pioneering online platform, we are committed to transforming the landscape of industrial services, connecting manufacturers with
            cutting-edge solutions that redefine the way business is done.
          </p>
          <p>
            <b> Our Mission:</b> Empowering manufacturers with seamless access to a comprehensive range of industrial services, fostering growth,
            sustainability, and operational excellence.
          </p>
          <p>
            <b>Who We Are:</b> Founded on the principles of innovation and customer-centricity, <b>iBuzoo-eServices</b> brings together a team of industry
            experts, engineers, and technologists passionate about driving positive change in the manufacturing sector. With a deep understanding of the
            evolving needs of modern industries, we have crafted a platform that goes beyond conventional service models.
          </p>
          <h4>What Sets Us Apart:</h4>
          <p>
            <b> Digital Excellence:</b> Our online platform leverages state-of-the-art digital technologies to streamline and enhance the delivery of industrial
            services, making the process efficient and user-friendly.
          </p>
          <p>
            <b> Diverse Service Ecosystem:</b> From preventive maintenance and break down service to specialized consulting services, we offer a diverse array
            of solutions tailored to meet the unique requirements of manufacturers.
          </p>
          <p>
            <b> Global Network of Experts:</b> Collaborating with a global network of seasoned professionals, we ensure that our clients have access to the best
            minds and expertise in the industry.
          </p>
          <p>
            <b>Innovation Hub:</b> iBuzoo-eServicesis not just a service provider; it's an innovation hub. We actively seek out and promote innovative
            technologies that can propel manufacturing businesses forward.
          </p>
          <p>
            <b>Customer-Centric Approach:</b> We prioritize the needs and goals of our clients. Our platform is designed to provide a personalized experience,
            enabling manufacturers to find and engage with the services that precisely match their requirements.
          </p>
          <h4>
            <i>Why ChooseiBuzoo-eServices:</i>
          </h4>
          <p>
            <b>Reliability:</b> Count on us for consistent and reliable industrial services that align with the highest industry standards.
          </p>
          <p>
            <b>Innovation:</b> Stay at the forefront of technological advancements with our commitment to continuous innovation.
          </p>
          <p>
            <b>Global Reach:</b> Connect with solution experts from around the world, expanding your options and opportunities.
          </p>
          <p>
            <b>Efficiency:</b> Experience streamlined processes that save your time and resources, allowing you to focus on your core business.
          </p>
          <p>
            we are your strategic partner in navigating the complexities of the manufacturing industry. Join us in shaping the future of industrial services—one
            that is digital, dynamic, and driven by excellence. Welcome to a new era of manufacturing solutions with <b>iBuzoo-eServices</b>.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
