import React from "react";
import s from "./MoreiBuzoo.module.css";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
import contactimg from "./../../assets/Banner/contact.jpg";
const Contact = () => {
  return (
    <>
      <div className={s["about-container"]}>
        <BreadcrumbSection tittle="Contact" />
        <img src={contactimg} style={{ borderRadius: "7px", width: "100%", height: "300px" }} />
        <div className={s["about-title"]}>
          <h1>Contact Us</h1>
        </div>
        <div className="about-layout pb-5">
          <h4>Customer Support:</h4>
          <ul>
            <li>
              Email: <a href="mailto:info.ibuzoo@gmail.com">info.ibuzoo@gmail.com</a>
            </li>
            <li>
              Contact:<a href="tel:+91 837 391 1193"> +91-8373911193</a>
            </li>
          </ul>

          <h4>Technical Support:</h4>
          <p>For partnership opportunities, business collaborations, or media inquiries, please reach out to our business team.</p>
          <ul>
            <li>
              Email: <a href="mailto:support@ibuzoo.com"> support@ibuzoo.com</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Contact;
