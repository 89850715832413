import React from "react";
import Help from "../Components/Vender/Help";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";

const UserHelp = () => {
  return (
    <div>
      <Header />
      <Help />
      <Footer />
    </div>
  );
};

export default UserHelp;
