import React, { useEffect } from "react";
import s from "./vender.module.css";
import { useState } from "react";
import verder, { venderregister_api, get_otp } from "../api/vender";
import { toast } from "react-toastify";
import ProfessionalOtpVerify from "../DialogBox/ProfessionalOtpVerify";
import { get_state, get_city } from "../api/profile";
import { MdVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const LoginPage = () => {
  const [terms, setterms] = useState(false);
  const [register, setregister] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [otyverify, setotyverify] = useState(false);
  const [verify, setverify] = useState(false);
  const navigate = useNavigate();
  const [type, settype] = useState("");
  const [stateuser, setStateUser] = useState([]);
  const [cityuser, setCityUser] = useState([]);
  const [validationMessage, setValidationMessage] = useState(false);
  const [gsterror, setgsterror] = useState(false);
  const [panerror, setpanerror] = useState(false);
  const [userdata, setuserdata] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    building: "",
    street: "",
    locality: "",
    city: "",
    state: "",
    country: "",
    gstNo: "",
    panNo: "",
    aadharNo: "",
    pincode: "",
  });

  const get_state_fn = async () => {
    try {
      const res = await get_state();
      if (res.data.status) {
        setStateUser(res.data.data.states);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  const get_city_fn = async () => {
    try {
      const res = await get_city(userdata.state);
      if (res.data.status) {
        setCityUser(res.data.data.cities);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };

  useEffect(() => {
    get_city_fn();
  }, [userdata.state]);

  useEffect(() => {
    get_state_fn();
  }, []);

  const userinfofun = (e) => {
    if (e.target.name === "phone") {
      setverify(false);
    }
    setuserdata({ ...userdata, [e.target.name]: e.target.value });
  };

  const formsubmit = async () => {
    if (!validationMessage) {
      toast.warn("Please enter a valid aadhar number");
      return;
    }
    if (!panerror) {
      toast.warn("Please enter a valid PAN number");
      return;
    }
    if (!gsterror) {
      toast.warn("Please enter a valid GST number");
      return;
    }
    if (!phoneerror) {
      toast.warn("Please enter a valid phone number");
      return;
    }
    if (!emailerror) {
      toast.warn("Please enter a valid email number");
      return;
    }
    if (!terms) return alert("Please select, I agree all statements in Terms of service");
    const temp = {
      phone: userdata.phone,
      name: userdata.name,
      email: userdata.email,
      address: userdata.address,
      company: userdata.company,
      building: userdata.building,
      street: userdata.street,
      locality: userdata.locality,
      city: userdata.city,
      state: userdata.state,
      country: userdata.country,
      gst_no: userdata.gstNo,
      pan_no: userdata.panNo,
      aadhar_no: userdata.aadharNo,
      pincode: userdata.pincode,
    };
    try {
      const res = await venderregister_api(temp);
      if (res.data.status) {
        toast.success(res.data.message);
        Cookies.set("venderauth", "true", { secure: false }, { expires: 365 });
        Cookies.set("venderaccessToken", res.data.data.token, { secure: false }, { expires: 365 });
        navigate("/vender/dashboard");
        setverify(false);
        setuserdata({
          ...userdata,
          phone: "",
          name: "",
          email: "",
          address: "",
          company: "",
          building: "",
          street: "",
          locality: "",
          city: "",
          state: "",
          country: "",
          gst_no: "",
          pan_no: "",
          aadhar_no: "",
        });
      } else {
        toast.warn(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Proceedtologin = async () => {
    settype("login");
    if (!userdata.phone) {
      toast.warn("Please enter a phone number");
      return;
    }
    setisloading(true);
    let temp = {
      phone: userdata.phone,
    };
    try {
      const res = await verder(temp);
      if (res.data.status) {
        toast.success(res.data.message);
        setotyverify(true);
        setisloading(false);
        return;
      }
      toast.danger(res.data.message);
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };
  const handleOtp = async () => {
    settype("register");
    if (!userdata.phone) {
      toast.warn("Please enter a phone number");
      return;
    }
    setisloading(true);
    let temp = {
      phone: userdata.phone,
    };
    try {
      const res = await get_otp(temp);
      if (res.data.status) {
        toast.success(res.data.message);
        setotyverify(true);
        setisloading(false);
        return;
      }
      toast.danger(res.data.message);
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const validateAadhar = (input) => {
    // Remove spaces and dashes from the Aadhar number
    const cleanedAadharNumber = input.replace(/[\s-]/g, "");
    // Check if the Aadhar number is a 12-digit numeric value
    if (/^\d{12}$/.test(cleanedAadharNumber)) {
      setValidationMessage(true);
    } else {
      setValidationMessage(false);
    }
  };

  const validateGSTIN = (input) => {
    // Check if the GSTIN is in the correct format
    if (/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/.test(input)) {
      setgsterror(true);
    } else {
      setgsterror(false);
    }
  };
  const [emailerror, setemailerror] = useState(false);
  const [phoneerror, setphoneerror] = useState(false);

  const validatePAN = (input) => {
    // Check if the PAN is in the correct format
    if (/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(input)) {
      setpanerror(true);
    } else {
      setpanerror(false);
    }
  };

  const validatePhoneNumber = (input) => {
    // Check if the phone number is in a valid format
    if (/^\d{10}$/.test(input)) {
      setphoneerror(true);
    } else {
      setphoneerror(false);
    }
  };

  const validateEmail = (input) => {
    // Check if the email is in a valid format
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input)) {
      setemailerror(true);
    } else {
      setemailerror(false);
    }
  };
  return (
    <div>
      {register && (
        <div classNameName={s["main"]}>
          <div className="container">
            <section className={s["signup"]}>
              <h2 className={s["form-title"]}>Register as a Services Partner</h2>

              <div className={s["signup-content"]}>
                <div className="left-signup-form">
                  <div className="d-flex" style={{ alignItems: "center", gap: "1rem" }}>
                    <div class="form-group mt-2" style={{ flexGrow: "1" }}>
                      <label for="exampleInputPassword1">Phone</label>
                      <input
                        type="number"
                        name="phone"
                        value={userdata.phone}
                        onChange={(e) => {
                          validatePhoneNumber(e.target.value);
                          userinfofun(e);
                        }}
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter phone"
                      />
                      {userdata.phone.length > 9 && !phoneerror && <div style={{ color: "red" }}>Invalid Phone. Please enter a valid Phone number.</div>}
                    </div>
                    {verify ? (
                      <div className="form-group form-button">
                        <div className={s["form-submit"]} style={{ backgroundColor: "green" }}>
                          <MdVerified />
                        </div>
                      </div>
                    ) : (
                      <div className="form-group form-button" onClick={() => handleOtp()}>
                        <input type="submit" name="signup" id="signup" className={s["form-submit"]} value="Get OTP" />
                      </div>
                    )}
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={userdata.name}
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter name"
                    />
                  </div>

                  <div class="form-group mt-2">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      name="email"
                      value={userdata.email}
                      onChange={(e) => {
                        validateEmail(e.target.value);
                        userinfofun(e);
                      }}
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    {userdata.email.length > 2 && !emailerror && <div style={{ color: "red" }}>Invalid Email. Please enter a valid email.</div>}
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">Company Name</label>
                    <input
                      type="text"
                      name="company"
                      value={userdata.company}
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter company"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">Building Name</label>
                    <input
                      type="text"
                      name="building"
                      value={userdata.building}
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter building"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">Street</label>
                    <input
                      type="text"
                      name="street"
                      value={userdata.street}
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter street"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">Locality</label>
                    <input
                      type="text"
                      name="locality"
                      value={userdata.locality}
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter locality"
                    />
                  </div>
                </div>
                <div className={s["signup-form"]}>
                  <div className="form-group mt-2">
                    <label htmlFor="state">State</label>
                    <select name="state" onChange={(e) => userinfofun(e)} className="form-control" id="state">
                      <option selected>Choose state</option>
                      {stateuser?.map((elem, id) => {
                        return <option value={elem._id}>{elem.name}</option>;
                      })}
                    </select>
                  </div>

                  <div className="form-group mt-2">
                    <label htmlFor="state">City</label>
                    <select name="city" onChange={(e) => userinfofun(e)} className="form-control" id="state">
                      <option selected>Choose city</option>
                      {cityuser?.map((elem, id) => {
                        return <option value={elem._id}>{elem.name}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">Country</label>
                    <input
                      type="text"
                      name="country"
                      value={userdata.country}
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter country"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">GST No.</label>
                    <input
                      type="text"
                      name="gstNo"
                      value={userdata.gstNo}
                      onChange={(e) => {
                        validateGSTIN(e.target.value);
                        userinfofun(e);
                      }}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="22ABCDEXXXXXXXX"
                    />
                    {userdata.gstNo.length > 2 && !gsterror && <div style={{ color: "red" }}>Invalid GSTIN. Please enter a valid GSTIN.</div>}
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">PAN No.</label>
                    <input
                      type="text"
                      name="panNo"
                      value={userdata.panNo}
                      onChange={(e) => {
                        validatePAN(e.target.value);
                        userinfofun(e);
                      }}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="ABCDE1234F"
                    />
                    {userdata.panNo.length > 2 && !panerror && <div style={{ color: "red" }}>Invalid PAN. Please enter a valid PAN.</div>}
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">Aadhar No.</label>
                    <input
                      type="text"
                      name="aadharNo"
                      value={userdata.aadharNo}
                      onChange={(e) => {
                        validateAadhar(e.target.value);
                        userinfofun(e);
                      }}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="XXXX XXXX XXXX"
                    />
                    {userdata.aadharNo.length > 2 && !validationMessage && (
                      <div style={{ color: "red" }}>Invalid Aadhar card number. Please enter a 12-digit numeric value.</div>
                    )}
                  </div>
                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1">Pincode</label>
                    <input
                      type="text"
                      name="pincode"
                      value={userdata.pincode}
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter Pincode"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mt-2" style={{ display: "flex", gap: "0.2rem", alignItems: "baseline" }}>
                <input
                  type="checkbox"
                  name="agree-term"
                  checked={terms ? true : false}
                  id="agree-term"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setterms(true);
                      return;
                    } else {
                      setterms(false);
                    }
                  }}
                  className="agree-term"
                />
                <label for="agree-term" className="label-agree-term">
                  <span>
                    <span></span>
                  </span>
                  I agree all statements in
                  <span className="term-service" style={{ cursor: "pointer", color: "blue" }} onClick={() => navigate("/vendor-terms-of-service")}>
                    Terms of service
                  </span>
                </label>
              </div>

              <div className="form-group form-button" onClick={() => formsubmit()} style={{ textAlign: "center", maxWidth: "fit-content", margin: "auto" }}>
                <input type="submit" name="signup" id="signup" className={s["form-submit"]} value="Register" />
              </div>

              <div className={s["signup-image"]} style={{ textAlign: "center", paddingTop: "1rem" }}>
                <a
                  className="signup-image-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    setregister(false);
                  }}
                >
                  Already have an account ?<span style={{ color: "blue" }}> Login In</span>
                </a>
              </div>
            </section>
          </div>
        </div>
      )}
      {!register ? (
        <div classNameName={s["main"]}>
          <section className={s["signup"]}>
            <div className="container">
              <div className={s["signup-content"]}>
                <div className={s["signup-image"]}>
                  <figure>
                    <img src="https://colorlib.com/etc/regform/colorlib-regform-7/images/signup-image.jpg" alt="sing up image" />
                  </figure>
                  <a className="signup-image-link" style={{ cursor: "pointer" }} onClick={() => setregister(true)}>
                    Register as a Services Partner
                  </a>
                </div>
                <div className={s["signup-form"]}>
                  <h2 className={s["form-title"]}>Login</h2>

                  <div class="form-group mt-2">
                    <label for="exampleInputPassword1" className="pb-2">
                      Phone
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={userdata.phone}
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter phone"
                    />
                  </div>

                  <div className="form-group form-button" style={{ maxWidth: "fit-content", margin: "auto" }} onClick={() => Proceedtologin()}>
                    <input type="submit" name="signup" id="signup" className={s["form-submit"]} value="Login" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      <ProfessionalOtpVerify open={otyverify} phone={userdata.phone} type={type} onclose={() => setotyverify(!otyverify)} onsubmit={() => setverify(true)} />
    </div>
  );
};

export default LoginPage;
