import React from "react";
import axios from "axios";
import { getBaseUrl } from "../utils";
import { useEffect } from "react";
import Cookies from "js-cookie";

const verderlogin = getBaseUrl() + "api/vendor/login";
const getOtp = getBaseUrl() + "api/vendor/getPhoneOtp";
const venderregister = getBaseUrl() + "api/vendor/register";
const verify_otp = getBaseUrl() + "api/vendor/verifyPhone";
const allocated_bookings = getBaseUrl() + "api/vendor/allocated_bookings";
const update_booking_status = getBaseUrl() + "api/vendor/update_booking_status";
const vendorprofile = getBaseUrl() + "api/vendor/profile";
const vender_login_verify_otp_api = getBaseUrl() + "api/vendor/verify_otp";
const reject_booking_vendor = getBaseUrl() + "api/vendor/update_booking_status";
const get_invoices_vendor = getBaseUrl() + "api/vendor/invoices";
const upload_booking_invoice = getBaseUrl() + "api/vendor/upload_booking_invoice";
const vendor_bank_details = getBaseUrl() + "api/vendor/bank_details";
const vendor_profile_update = getBaseUrl() + "api/vendor/profile";
const customer_reviews = getBaseUrl() + "api/vendor/customer_reviews";
const vendor_home_data = getBaseUrl() + "api/vendor/home_data";

export const mail_payment_slip_api = async (id) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.get(getBaseUrl() + `api/vendor/mail_payment_slip/${id}`, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const vendor_home_data_api = async () => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.get(vendor_home_data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const customer_reviews_api = async () => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.get(customer_reviews, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const vendor_upload_documents_api = async (data, fd) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.post(getBaseUrl() + `api/vendor/upload_documents/${data.name}`, fd, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const vendor_profile_update_api = async (data) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.patch(vendor_profile_update, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const vendor_bank_details_api = async (data) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.patch(vendor_bank_details, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const upload_booking_invoice_api = async (data) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.post(upload_booking_invoice, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const get_invoices_vendor_api = async (type) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.get(get_invoices_vendor, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const notifications_vendor_api = async (type) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
    };
    return await axios.get(getBaseUrl() + `api/vendor/inbox?type=${type}`, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const reject_booking_vendor_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
  };
  try {
    let res = await axios.patch(reject_booking_vendor, data, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const vendorprofile_api = async () => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
  };
  try {
    let res = await axios.get(vendorprofile, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const allocated_bookings_api = async () => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
  };
  try {
    let res = await axios.get(allocated_bookings, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const update_booking_status_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("venderaccessToken")}` },
  };
  try {
    let res = await axios.patch(update_booking_status, data, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const vender_login_verify_otp_api_api = async (data) => {
  try {
    let res = await axios.post(vender_login_verify_otp_api, data);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const vender_verify_otp_api = async (data) => {
  try {
    let res = await axios.post(verify_otp, data);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const venderregister_api = async (data) => {
  try {
    let res = await axios.post(venderregister, data);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const verder = async (data) => {
  try {
    let res = await axios.post(verderlogin, data);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default verder;

export const get_otp = async (data) => {
  try {
    let res = await axios.post(getOtp, data);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
