import React from "react";
import LoginPage from "../Components/Vender/LoginPage";
import NavbarTop from "../Common/Header/NavbarTop";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import WhyChoose from "../Components/Home/WhyChoose";
import RecentServices from "../Components/Home/RecentServices";
import OurClient from "../Components/Home/OurClient";

const VenderLogin = () => {
  return (
    <>
      <NavbarTop />
      <Header />
      <LoginPage />
      <RecentServices />
      {/* <OurClient /> */}
      <Footer />
    </>
  );
};

export default VenderLogin;
