import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ProtectedDialog from "./ProtectedDialog";
const Protected = ({ children }) => {
  const auth = Cookies.get("auth");
  const navigate = useNavigate();
  const [Loginpopup, setLoginpopup] = useState(false);
  if (auth === "true") {
    return children;
  }
  return <ProtectedDialog open={true} />;
};

export default Protected;
