import React from "react";
import NavbarTop from "../Common/Header/NavbarTop";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import Checkout from "../Components/Checkout/Checkout";
const CheckoutPage = () => {
  return (
    <>
      <NavbarTop />
      <Header />
      <Checkout />
      <Footer />
    </>
  );
};

export default CheckoutPage;
