import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetch_service_details } from "../api/Category";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import RequestCallback from "../DialogBox/RequestCallback";
import Loder from "../Loder/Loder";

const ServicesDetails = () => {
  const { id } = useParams();
  const [servicedetails, setservicedetails] = useState([]);
  const auth = Cookies.get("auth");
  const [service_id, setservice_id] = useState("");
  const [isloading, setisloading] = useState(false);
  const [RequestCallbackpopup, setRequestCallbackpopup] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchservicedetails();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [id]);
  const fetchservicedetails = async () => {
    setisloading(true);
    try {
      let res = await fetch_service_details(id);
      if (res.data.status) {
        setisloading(false);
        setservicedetails(res.data.data);
      }
    } catch (error) {
      console.log("error");
      setisloading(false);
    }
  };
  return (
    <div>
      <div class="container mt-5 mb-5">
        <div class="row">
          <div class="col-sm-6 col-md-6">
            <img style={{ height: "300px", width: "auto" }} src={servicedetails?.image} alt="" />
          </div>
          <div class="col-sm-6 col-md-5">
            <h2 class="animated zoomIn">{servicedetails?.name}</h2>
            <div></div>
            <p class="title-sub-banner" dangerouslySetInnerHTML={{ __html: servicedetails.description }}></p>
            <div class="mt-1 mb-1 spec-1">
              <span class="dot"></span>
              <span>
                Service Mode -
                <span style={{ textTransform: "uppercase", color: "#000" }}>
                  {" "}
                  <b>{servicedetails?.service_mode}</b>
                </span>
              </span>
            </div>
            <div class="mt-1 mb-1 spec-1">
              <span class="dot"></span>
              <span>Estimate Time - {servicedetails?.estimate_time} min</span>
            </div>
            {servicedetails.consult_charge != 0 && (
              <div class="mt-1 mb-1 spec-1" style={{ display: "flex", alignItems: "center" }}>
                <button
                  class="btn btn-outline-primary btn-sm mt-2"
                  onClick={() => {
                    if (!auth) {
                      toast.warn("Please Login");
                      return;
                    }
                    navigate("/checkout2", { state: servicedetails });
                  }}
                  style={{ backgroundColor: "black", border: "black", color: "#fff" }}
                  type="button"
                >
                  Consult ₹{servicedetails?.consult_charge} Now
                </button>
              </div>
            )}
            <div class="align-items-center align-content-center col-md-3 border-left mt-1">
              {servicedetails.service_mode === "both" && (
                <div class="d-flex flex-column mt-4">
                  <button
                    class="btn btn-primary btn-sm"
                    style={{ backgroundColor: "black", border: "black" }}
                    type="button"
                    onClick={() => {
                      setservice_id(servicedetails._id);
                      setRequestCallbackpopup(true);
                    }}
                  >
                    OnSite Service
                  </button>
                  <button
                    class="btn btn-outline-primary btn-sm mt-2"
                    onClick={() => {
                      if (!auth) {
                        toast.warn("Please Login");
                        return;
                      }
                      navigate("/checkout", { state: servicedetails });
                    }}
                    style={{ backgroundColor: "black", border: "black", color: "#fff" }}
                    type="button"
                  >
                    Online Service
                  </button>
                </div>
              )}
              {servicedetails.service_mode === "onsite" && (
                <div class="d-flex flex-column mt-4">
                  <button
                    class="btn btn-primary btn-sm"
                    style={{ backgroundColor: "black", border: "black" }}
                    type="button"
                    onClick={() => {
                      setservice_id(servicedetails._id);
                      setRequestCallbackpopup(true);
                    }}
                  >
                    OnSite Service
                  </button>
                </div>
              )}
              {servicedetails.service_mode === "online" && (
                <div class="d-flex flex-column mt-4">
                  <button
                    class="btn btn-outline-primary btn-sm mt-2"
                    onClick={() => {
                      if (!auth) {
                        toast.warn("Please Login");
                        return;
                      }
                      navigate("/checkout", { state: servicedetails });
                    }}
                    style={{ backgroundColor: "black", border: "black", color: "#fff" }}
                    type="button"
                  >
                    Online Service
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <RequestCallback open={RequestCallbackpopup} service_id={service_id} onclose={() => setRequestCallbackpopup(!RequestCallbackpopup)} />
      <Loder loading={isloading} />
    </div>
  );
};

export default ServicesDetails;
