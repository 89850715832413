import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import s from "./checkout.module.css";
import { toast } from "react-toastify";
import create_consult_api, { init_consult_payment_api } from "../api/consult";

import Loder from "../Loder/Loder";

const ConsultCheckout = () => {
  const navigate = useNavigate();
  const [consult_id, setconsult_id] = useState("");
  const [paybutton, setpaybutton] = useState(false);
  const [slotbutton, setslotbutton] = useState(true);
  const [order_id, setorder_id] = useState("");
  const [date, setdate] = useState("");
  const [value, setvalue] = useState("");
  const location = useLocation();
  const [isloading, setisloading] = useState(false);
  const amount = location.state.service_charge;

  const [finalprice, setfinalprice] = useState({
    delivery_charges: "0",
    gst: Number(location.state.consult_charge * 18) / 100,
    item_total: "1",
    mrp: "",
    consult_charge: location.state.consult_charge,
    paying_amount: location.state.service_charge,
    name: location.state.name,
  });

  const adddetails = async () => {
    if (date == "") return toast.error("Please Select Service Slot  !");
    setisloading(true);
    let temp = {
      service_id: location.state._id,
      scheduled_date: date,
    };
    try {
      const res = await create_consult_api(temp);
      if (res.data.status) {
        toast.success(res.data.message);
        setconsult_id(res.data.data.consult._id);
        setpaybutton(true);
        console.log(paybutton);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      setisloading(false);
    }
  };

  const procesedtopayment = async () => {
    setisloading(true);
    let temp = {
      consult_id: consult_id,
    };
    try {
      const res = await init_consult_payment_api(temp);
      if (res.data.status) {
        setorder_id(res.data.data.order.id);
        proceeds(res.data.data.order.id);
        setisloading(false);
        return;
      }
      toast.error(res.data.message);
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const proceeds = (order_id) => {
    if (amount !== "") {
      var options = {
        key: process.env.REACT_APP_RZP_LIVE_KEY,
        amount: 200 * 100,
        currency: "INR",
        name: "iBuzoo",
        description: "Transaction",
        first: "Transaction",
        image: "",
        order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          onsuccess(response.razorpay_payment_id);
          console.log(response.razorpay_payment_id);
          console.log(response.razorpay_order_id);
          console.log(response.razorpay_signature);
          console.log(response);
        },
        prefill: {
          name: "jatin",
          email: "jatin@gmail.com",
          contact: "6397823778",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#aqua-primary",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        setTimeout(() => {
          // setpaymentsuccess(false);
          // navigate("/add_wallet");
        }, 3000);
        // SendpaymentData("Payment Not Successfull");
        // alert(response.error.code);
        // setstatus(false);
        // setpaymentsuccess(true);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);

        console.log(response);
      });
      rzp1.open();
    } else {
      alert("Enter Valid Amount");
    }
  };

  const onsuccess = (id) => {
    navigate("/consult");
  };

  return (
    <>
      <div className="container mt-4 mb-4">
        <span onClick={() => navigate(-1)}>
          <button className={s["arrow_back"]}>
            <AiOutlineArrowLeft /> Back
          </button>
        </span>
        <div className={s["delevery_addres"]}>
          <div className="layout-container">
            <div className={s["address_left"]}>
              <div className={["address_left"]} style={{ borderTop: "2px solid #777" }}>
                <div className={s["address_bg"]} style={{ marginTop: "1rem" }}>
                  <div className={s["number_add"]}>1</div>
                  <div className="delevry_heading"> Consult Slot</div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <input
                        type="datetime-local"
                        value={date}
                        onChange={(e) => setdate(e.target.value)}
                        className="form-control"
                        min={new Date().toISOString().replace(/T.*/, "")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <button className={`${s["btn-layout"]} ${s["btn-layout-main"]}`} onClick={() => adddetails()}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={s["address_right"]}>
              <div className="product_details">
                <span>Price details</span>
              </div>
              <div className="product_details">
                <div className="product_details">
                  <div className="d-flex" style={{ justifyContent: "space-between" }}>
                    <div>Service Name:</div>
                    <div>{finalprice.name}</div>
                  </div>
                </div>
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                  <div> Mode :</div>
                  <div style={{ fontWeight: "bold", textTransform: "uppercase" }}>Consult</div>
                </div>
              </div>
              <div className="product_details">
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                  <div>Estimated GST Charges:</div>
                  <div>
                    <div>{finalprice.gst}</div>
                  </div>
                </div>
              </div>

              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <div>
                  <h5>Total Payable</h5>
                </div>
                <div>
                  <h4>₹ {Number(finalprice.consult_charge) + Number(finalprice.gst)}</h4>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "end", marginBlock: "1rem" }}>
                {paybutton ? (
                  <button className={`${s["btn-layout"]} ${s["btn-layout-main"]}`} onClick={() => procesedtopayment()}>
                    Pay ₹ {Number(finalprice.consult_charge) + Number(finalprice.gst)}
                    <AiOutlineArrowRight size={20} />
                  </button>
                ) : (
                  <button disabled className={`${s["btn-layout-disabled"]} ${s["btn-layout-main-disabled"]}`}>
                    Pay ₹ {Number(finalprice.consult_charge) + Number(finalprice.gst)}
                    <AiOutlineArrowRight size={20} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loder loading={isloading} />
    </>
  );
};

export default ConsultCheckout;
