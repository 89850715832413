import React from "react";
import AllocatedBookings from "./AllocatedBookings";

const Booking = () => {
  return (
    <div>
      <AllocatedBookings />
    </div>
  );
};

export default Booking;
