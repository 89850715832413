import axios from "axios";
import { getBaseUrl } from "./../utils";
import Cookies from "js-cookie";

const coupons = getBaseUrl() + "api/user/coupons";
const apply_coupon = getBaseUrl() + "api/user/apply_coupon";

export const apply_coupon_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    method: "post",
    url: apply_coupon,
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const get_coupons_api = async () => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    method: "get",
    url: coupons,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
