import React, { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { vendor_bank_details_api, vendorprofile_api } from "../api/vender";

const BankDetails = () => {
  const [isloading, setisloading] = useState(false);
  const [bankdetails, setbankdetails] = useState({});
  const [vendordataArry, setvendordataArry] = useState({
    bank_name: "",
    acc_holder_name: "",
    acc_no: "",
    ifsc: "",
    branch: "",
  });
  const { bank_name, acc_holder_name, acc_no, ifsc, branch } = vendordataArry;

  const userupdate = (e) => {
    setvendordataArry({
      ...vendordataArry,
      [e.target.name]: e.target.value,
    });
  };

  const vendor_bank_details = async () => {
    setisloading(true);
    let temp = {
      bank_name,
      acc_holder_name,
      acc_no,
      ifsc,
      branch,
    };
    try {
      const res = await vendor_bank_details_api(temp);
      if (res.data.status) {
        toast.success(res.data.message);
      } else {
        console.log("data response error:::", res);
        toast.error(res.data.message);
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  useEffect(() => {
    vendorprofile();
  }, []);
  const vendorprofile = async () => {
    setisloading(true);
    try {
      const res = await vendorprofile_api();
      if (res.data.status) {
        const { bank_name, acc_holder_name, acc_no, ifsc, branch } = res?.data?.data?.vendor?.bank_details;
        setvendordataArry({
          bank_name: bank_name,
          acc_holder_name: acc_holder_name,
          acc_no: acc_no,
          ifsc: ifsc,
          branch: branch,
        });
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      // toast.error("Error Notification !");
      setisloading(false);
    }
  };

  return (
    <div>
      <div className="Contact_Page_padding">
        <div className="container">
          <div className="card_section pb-5">
            <Card className="Card_shadow p-3 mt-5">
              <div className="text-center mt-5 mb-3">
                <h4>Bank Details</h4>
              </div>

              <div className="card_admissiondetails_height">
                <div className="textfiled_margin">
                  <div class="container">
                    <div class="row">
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Bank Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="bank_name"
                            placeholder="Bank Name"
                            value={bank_name}
                            onChange={(e) => userupdate(e)}
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Account Holder Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="acc_holder_name"
                            placeholder="Account Holder Name"
                            value={acc_holder_name}
                            onChange={(e) => userupdate(e)}
                          />
                        </div>
                      </div>

                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Account Number</label>
                          <input
                            type="number"
                            className="form-control"
                            name="acc_no"
                            placeholder="Account Number"
                            value={acc_no}
                            onChange={(e) => userupdate(e)}
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Ifsc Code</label>
                          <input type="nember" className="form-control" name="ifsc" placeholder="Ifsc Code" value={ifsc} onChange={(e) => userupdate(e)} />
                        </div>
                      </div>

                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Branch Name</label>
                          <input
                            style={{ textTransform: "uppercase" }}
                            type="text"
                            className="form-control"
                            name="branch"
                            placeholder="Branch Name"
                            value={branch}
                            onChange={(e) => userupdate(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center-button mt-1 ">
                    <button type="button" className="btn get_otp_btn_userprofile_btn" onClick={() => vendor_bank_details()}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
