import React from "react";
import s from "./custombutton.module.css";

const custombutton = ({ text, loading }) => {
  return (
    <>
      <div className={s["customButton-container"]}>
        <div className={s["customButton-layout"]}>{loading ? "Loading..." : text}</div>
      </div>
    </>
  );
};

export default custombutton;
