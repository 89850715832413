import axios from "axios";
import { getBaseUrl } from "./../utils";
import Cookies from "js-cookie";

export const fetchAllbanner = async (data) => {
  try {
    let temp = {
      url: getBaseUrl() + `api/user/banners`,
    };
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.get(temp.url, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
