import React from "react";
import s from "./MoreiBuzoo.module.css";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
const Privacypolicy = () => {
  return (
    <>
      <div className={s["privacypolicy-container"]}>
        <BreadcrumbSection tittle="Privacy policy" />
        <div className={s["privacypolicy-title"]}>
          <h1>Privacy Policy</h1>
        </div>
        <div className="privacypolicy-layout pb-5">
          <p>
            This privacy policy is all about how we maintain and use our users/visitors/customers personal and commercial information collected by the site.
          </p>
          <p>
            <b>“iBuzoo-eServices ” </b>understands the importance of privacy of its users
          </p>

          <h4>Consent</h4>
          <p>By registering on the website or using it (Online/Offline) the user agrees to the practices describedin the Privacy Policy.</p>
          <h4>Personal or commercial information we collect:</h4>
          <p>
            You can search the services and see the prices for the online services without registering with us or creating an account on the portal. However,
            for buying and use other services you have to register with us by providing some personal information like Name, Mobile No, Address, email id etc.
          </p>
          <p>
            <b>Withdraw of consent:</b>
          </p>
          <p>
            After registering if you want to withdraw your consent of using your personal information you can mail us on{" "}
            <a href="mailto:customer.care@ibuzoo-eservices.co.in">
              {" "}
              <b>customer.care@ibuzoo-eservices .co.in</b>
            </a>
            ;
          </p>
          <h4>Cookies, Promotion and Advertisements:</h4>
          <p>Cookies are text files which contains small set of datathat are used to identify your system when you use the website.</p>
          <p>
            When you allow, this website use cookies to track product search history, purchase history, session history etc. to provide personalize service
            experience.
          </p>
          <p>
            Theses cookies may be used by our promotion and advertisement service provider company or advertisement server or tools. This is a standard industry
            trend /technology which is used by all websites.
          </p>
          <h4>Protection of your Information:</h4>
          <p>
            We ensure the security of your personal information. This data will be only accessible to authorized employees, developers and trusted promotion
            agencies.
          </p>
          <h4>CANCELLATION AND REFUND</h4>
          <ul style={{ listStyle: "disc", padding: "revert" }}>
            <li>
              No refund shall be processed on the cancellation of any order under any circumstances if the order is assigned to any Service provider. The risk
              and liability of placing order in a haste and careless manner totally lies with the User and the Website is not responsible for any refund{" "}
            </li>
            <li>
              No refund shall be processed once the Order has been placed and executed. However, if the User intends to cancel a successfully placed order
              before execution, the User is required to contact the support team within 20min. of making the payment, whereafter it is totally at the discretion
              of the Website whether to issue refund.
            </li>
            <li>
              No refund shall be processed for the reason that in-correct information or data has been provided by You. The User agrees to be careful while
              providing any information to the Website and must re-check the information filled{" "}
            </li>
            <li>No refund shall be processed for providing a wrong contact number. No refund shall be processed for any call which gets connected.</li>
            <li>
              refunds, if any, shall be processed after deduction of the transaction charges levied by the Bank and/or the Payment Gateway, to & fro cost of the
              shipping and/or courier charges (With regard to purchase of a product listed on the Website), customs duty (if levied) and/or any other charges
              that may have been incurred by the Website during processing and/or delivering the service, as applicable.
            </li>
            <li>
              In case the Website or Payment gateway’s webpage, that is linked to the Website, is experiencing any server related issues like ‘slow down’ or
              ‘failure’ or ‘session timeout’, the User shall, before initiating the second payment, check whether his/her Bank Account has been debited or not
              and accordingly resort to one of the following options:
            </li>
            <p>
              In case the Bank Account appears to be debited, ensure that you do not make the payment twice and immediately thereafter contact the Website via
              customer care to confirm payment.
            </p>
            <p>In case the Bank Account is not debited, the User may initiate a fresh transaction to make payment.</p>
            <li>
              However, refund for multiple payment, if any, even after the above precaution against the same order shall be refunded in full without deduction
              of the transaction charges as mentioned above. The Website shall only retain the cost of one single order as intended to be placed by the User.
            </li>
            <li>
              If there are orders that the Website is unable to accept and must cancel, the Website at its sole discretion, reserves the right to refuse or
              cancel any order for any reason whatsoever. Some situations may result in the order being cancelled and include, without limitation,
              non-availability of the service, inaccuracy, error in pricing information or other problems as identified. If the User’s order is cancelled after
              charges being paid against the said service, the said amount paid for booking shall be refunded.
            </li>
            <li>Note: All refunds will be credited to user’s ibuzoo-services wallet.</li>
            <p>
              <b>Refunds will only be considered in the following cases:</b>
            </p>
            <p>Consultant is unable to respond fluently in the language mentioned in their profile</p>
            <p>Consultant is taking inordinately long enough to respond back to the user</p>
            <p>Consultant has responded back in irrelevant or inappropriate response to the query asked by the user</p>
          </ul>
          <p></p>
          <p></p>
        </div>
      </div>
    </>
  );
};

export default Privacypolicy;
