import React from "react";
import NavbarTop from "../Common/Header/NavbarTop";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import ConsultCheckout from "../Components/Checkout/ConsultCheckout";
const ConsultCheckoutPage = () => {
  return (
    <>
      <NavbarTop />
      <Header />
      <ConsultCheckout />
      <Footer />
    </>
  );
};

export default ConsultCheckoutPage;
