import React from "react";
import s from "./checkout.module.css";
import { booking_api, cancle_booking_api, initiate_payment_api } from "../api/checkout";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BookinCancleRegion from "../DialogBox/BookinCancleRegion";
import UserRating from "../DialogBox/UserRating";
import DataNotFound from "../../Common/DataNotFound";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
const BookingList = () => {
  const [isloading, setisloading] = useState("");
  const navigate = useNavigate();
  const [cancelregionPopup, setcancelregionPopup] = useState(false);
  const [bookingArry, setbookingArry] = useState([]);
  const [cancelbookingid, setcancelbookingid] = useState("");
  const [UserRatingpopup, setUserRatingpopup] = useState(false);
  useEffect(() => {
    getbooking();
  }, []);

  const getbooking = async () => {
    setisloading(true);
    try {
      const res = await booking_api();
      if (res.data.status) {
        console.log(res);
        setbookingArry(res.data.data.bookings);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const procesedtopayment = async (booking_id) => {
    if (booking_id == "" || booking_id == undefined) return;
    setisloading(true);
    let temp = {
      booking_id: booking_id,
    };
    try {
      const res = await initiate_payment_api(temp);
      if (res.data.status) {
        console.log(res);
        // toast.success(res.data.message);
        proceeds(res.data.data.order.id);
        setisloading(false);
        return;
      }
      // toast.danger(res.data.message);
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      // toast.danger("Error Notification !");
      setisloading(false);
    }
  };

  const proceeds = (order_id) => {
    // if (amount !== "") {
    var options = {
      key: process.env.REACT_APP_RZP_LIVE_KEY,
      amount: 200 * 100,
      currency: "INR",
      name: "iBuzoo",
      description: "Transaction",
      first: "Transaction",
      image: "",
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        onsuccess(response.razorpay_payment_id);
        console.log(response.razorpay_payment_id);
        console.log(response.razorpay_order_id);
        console.log(response.razorpay_signature);
        // alert(response.data.response.signatureIsValid);
        console.log(response);
      },
      // prefill: {
      //   name: "jatin",
      //   email: "jatin@gmail.com",
      //   contact: "6397823778",
      // },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#aqua-primary",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      setTimeout(() => {
        // setpaymentsuccess(false);
        // navigate("/add_wallet");
      }, 3000);
      // SendpaymentData("Payment Not Successfull");
      // alert(response.error.code);
      // setstatus(false);
      // setpaymentsuccess(true);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    // } else {
    //   alert("Enter Valid Amount");
    // }
  };

  const onsuccess = (id) => {
    getbooking();
  };

  return (
    <>
      <div className="container">
        <BreadcrumbSection tittle="Booking List" />
        <div className={s["booking-list"]}>
          {bookingArry.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">S.no</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Scheduled Date</th>
                  <th scope="col">Mode</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {bookingArry.map((data, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <img src={data?.service?.image} style={{ width: "70px", height: "50px" }} />
                    </td>
                    <td>{data?.service?.name}</td>
                    <td>{data?.scheduled_date}</td>
                    <td>{data?.service_mode}</td>
                    <td>{data?.booking_status}</td>
                    <td>
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        <div>
                          {data?.booking_status === "delivered" && (
                            <div className="">
                              <button
                                type="button"
                                class="btn btn-primary"
                                style={{ marginRight: "1px" }}
                                onClick={() => {
                                  setcancelbookingid(data._id);
                                  setUserRatingpopup(true);
                                }}
                              >
                                Rating
                              </button>
                            </div>
                          )}
                          {data?.booking_status === "initiated" && (
                            <div className="">
                              <button type="button" class="btn btn-primary" style={{ marginRight: "1px" }} onClick={() => procesedtopayment(data._id)}>
                                Book Now
                              </button>
                            </div>
                          )}
                          {data?.booking_status === "delivered" && !data?.service_charge_paid && (
                            <div className="">
                              <button type="button" class="btn btn-primary" style={{ marginRight: "1px" }} onClick={() => procesedtopayment(data._id)}>
                                Complete Payment
                              </button>
                            </div>
                          )}
                        </div>
                        {data?.booking_status === "initiated" ||
                          (data?.booking_status === "booked" && (
                            <div>
                              <button
                                type="button"
                                class="btn btn-danger"
                                onClick={() => {
                                  setcancelbookingid(data._id);
                                  setcancelregionPopup(true);
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <DataNotFound />
          )}
        </div>
      </div>
      <BookinCancleRegion
        open={cancelregionPopup}
        cancelbookingid={cancelbookingid}
        onclose={() => setcancelregionPopup(!cancelregionPopup)}
        onsubmit={() => getbooking()}
      />
      <UserRating open={UserRatingpopup} cancelbookingid={cancelbookingid} onclose={() => setUserRatingpopup(!UserRatingpopup)} />
    </>
  );
};

export default BookingList;
