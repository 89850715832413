import React from "react";
import s from "./checkout.module.css";
import { booking_api, cancle_booking_api, initiate_payment_api } from "../api/checkout";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BookinCancleRegion from "../DialogBox/BookinCancleRegion";
import UserRating from "../DialogBox/UserRating";
import DataNotFound from "../../Common/DataNotFound";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
const InvoiceList = () => {
  const [isloading, setisloading] = useState("");
  const navigate = useNavigate();
  const [cancelregionPopup, setcancelregionPopup] = useState(false);
  const [bookingArry, setbookingArry] = useState([]);
  const [cancelbookingid, setcancelbookingid] = useState("");
  const [UserRatingpopup, setUserRatingpopup] = useState(false);
  useEffect(() => {
    getbooking();
  }, []);

  const getbooking = async () => {
    setisloading(true);
    try {
      const res = await booking_api();
      if (res.data.status) {
        console.log(res);
        const cc = res?.data?.data?.bookings?.filter((data) => data?.booking_status !== "cancelled");
        setbookingArry(cc);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  return (
    <>
      <div className="container">
        <BreadcrumbSection tittle="Invoice List" />
        <div className={s["booking-list"]}>
          {bookingArry.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">S.no</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Scheduled Date</th>
                  <th scope="col">Mode</th>
                  <th scope="col">Status</th>
                  <th scope="col">Invoice</th>
                </tr>
              </thead>
              <tbody>
                {bookingArry.map((data, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <img src={data?.service?.image} style={{ width: "70px", height: "50px" }} />
                    </td>
                    <td>{data?.service?.name}</td>
                    <td>{data?.scheduled_date}</td>
                    <td>{data?.service_mode}</td>
                    <td>{data?.booking_status}</td>
                    <td>
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        <div>
                          {data?.user_invoice && (
                            <a href={data.user_invoice} target="_blank">
                              <button type="button" class="btn btn-primary" style={{ marginRight: "1px" }}>
                                View Invoice
                              </button>
                            </a>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <DataNotFound />
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceList;
