import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import s from "./DialogBox.module.css";
import { fetch_sub_category } from "../api/Category";
import { useNavigate } from "react-router-dom";
const Subcategories = ({ props, setCanvasOpen, data }) => {
  const navigate = useNavigate();
  console.log(data);

  const handleClose = () => {
    setCanvasOpen(false);
  };
  return (
    <div>
      <Offcanvas show={props} onHide={handleClose} placement="end" style={{ backgroundColor: "#f0f0f0" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Sub Category</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            {data.length > 0
              ? data.map((elem, id) => {
                  return (
                    <div className={s["offcanvas-category"]}>
                      <div className="d-flex justify-content-start align-items-center mb-2">
                        <img
                          onClick={() => navigate(`/category-service-page?sid=${elem._id}`)}
                          src={elem.image}
                          width={40}
                          style={{ marginRight: "15px", cursor: "pointer" }}
                        />
                        <p onClick={() => navigate(`/category-service-page?sid=${elem._id}`)} style={{ cursor: "pointer" }}>
                          {elem.name}
                        </p>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Subcategories;
