import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "./../utils";

const create_booking = getBaseUrl() + "api/user/create_booking";
const initiate_payment = getBaseUrl() + "api/user/initiate_payment";
const booking = getBaseUrl() + "api/user/booking";
const address = getBaseUrl() + "api/user/address";
const addaddress = getBaseUrl() + "api/user/address";
const request_callback = getBaseUrl() + "api/user/request_callback";
const user_review = getBaseUrl() + "api/user/booking/review";

export const user_review_api = async (data) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.post(user_review, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const cancle_booking_reason_api = async (id, data) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.delete(getBaseUrl() + `api/user/cancle_booking/${id}`, config, data);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const request_callback_api = async (data) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.post(request_callback, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const cancle_booking_api = async (id) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.delete(getBaseUrl() + `api/user/cancle_booking/${id}`, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteaddress_api = async (id) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.delete(getBaseUrl() + `api/user/address/${id}`, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const address_api = async () => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };

  try {
    let res = await axios.get(address, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const booking_api = async () => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };

  try {
    let res = await axios.get(booking, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const adduser_address_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };

  try {
    let res = await axios.post(addaddress, data, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const initiate_payment_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };

  try {
    let res = await axios.post(initiate_payment, data, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const create_booking_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };

  try {
    let res = await axios.post(create_booking, data, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default create_booking_api;
