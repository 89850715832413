import React, { useEffect, useState } from "react";
import Expand from "react-expand-animated";
import { useLocation, useNavigate } from "react-router-dom";
import { IoAdd } from "react-icons/io5";
import { AiFillDelete, AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import s from "./checkout.module.css";
import { toast } from "react-toastify";
import create_booking_api, { address_api, adduser_address_api, deleteaddress_api, initiate_payment_api } from "../api/checkout";
import { get_city, get_state } from "../api/profile";
import Loder from "../Loder/Loder";
import CouponsDiscount from "../Services/CouponsDiscount";
const Checkout = () => {
  const navigate = useNavigate();
  const [expandbox, setexpandbox] = useState(false);
  const [booking_id, setbooking_id] = useState("");
  const [paybutton, setpaybutton] = useState(false);
  const [slotbutton, setslotbutton] = useState(false);
  const [order_id, setorder_id] = useState("");
  const [date, setdate] = useState("");
  const [value, setvalue] = useState("");
  const location = useLocation();
  const [isloading, setisloading] = useState(false);
  const amount = location.state.service_charge;
  const [gst, setgst] = useState(Number(location.state.service_charge * 18) / 100);
  const [statevalue, setstatevalue] = useState("");
  const [cityvalue, setcityvalue] = useState("");
  const [discountprice, setdiscountprice] = useState("");
  const [code, setcode] = useState("");
  const [addAddress, setaddAddress] = useState({
    name: "",
    phone: "",
    address: "",
    landmark: "",
    pincode: "",
  });
  const [finalprice, setfinalprice] = useState({
    delivery_charges: "0",
    gst: Number(location.state.service_charge * 18) / 100,
    item_total: "1",
    mrp: "",
    paying_amount: location.state.service_charge,
    name: location.state.name,
  });

  const [alladresslist, setalladresslist] = useState([]);
  useEffect(() => {
    getalladress();
  }, []);
  const getalladress = async () => {
    setisloading(true);
    try {
      const res = await address_api();
      if (res.data.status) {
        setalladresslist(res?.data?.data?.address);
        console.log(res);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      toast.error("Error Notification !");
      setisloading(false);
    }
  };
  const adduseradress = async () => {
    if (addAddress.name == "") return toast.warn("Please enter your name !");
    if (addAddress.phone == "") return toast.warn("Please enter your number !");
    if (statevalue == "") return toast.warn("Select your State !");
    if (cityvalue == "") return toast.warn("Select  your City !");
    if (addAddress.pincode == "") return toast.warn("lease enter your pincode !");
    setisloading(true);
    const temp = {
      name: addAddress.name,
      phone: addAddress.phone,
      state: statevalue,
      city: cityvalue,
      pincode: addAddress.pincode,
      building: addAddress.address,
      landmark: addAddress.landmark,
    };
    try {
      const res = await adduser_address_api(temp);
      if (res.data.status) {
        getalladress();
        setaddAddress({
          name: "",
          phone: "",
          address: "",
          landmark: "",
          pincode: "",
        });
        toast.success(res.data.message);
        setexpandbox(false);
        setisloading(false);
        return;
      }
      toast.error(res.data.message);
      setisloading(false);
    } catch (error) {
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const adddetails = async () => {
    if (value == "") return toast.error("Please Select Address !");
    if (date == "") return toast.error("Please Select Service Slot  !");
    setisloading(true);
    let temp = {
      service_id: location.state._id,
      coupon_code: code,
      address: value,
      service_mode: "online",
      scheduled_date: date,
    };
    try {
      const res = await create_booking_api(temp);
      if (res.data.status) {
        console.log(res);
        toast.success(res.data.message);
        setbooking_id(res.data.data.booking._id);
        setslotbutton(false);
        procesedtopayment(res.data.data.booking._id);
        setpaybutton(true);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };
  const useraddress = (e) => {
    setaddAddress({ ...addAddress, [e.target.name]: e.target.value });
  };

  const datachecked = (data) => {
    setbooking_id("");
    setvalue(data._id);
    setslotbutton(true);
  };

  const procesedtopayment = async (data) => {
    setisloading(true);
    let temp = {
      booking_id: data || booking_id,
    };
    try {
      const res = await initiate_payment_api(temp);
      if (res.data.status) {
        console.log(res);
        // toast.success(res.data.message);
        setorder_id(res.data.data.order.id);
        proceeds(res.data.data.order.id);
        setisloading(false);
        return;
      }
      toast.error(res.data.message);
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const proceeds = (order_id) => {
    if (amount !== "") {
      var options = {
        key: process.env.REACT_APP_RZP_LIVE_KEY,
        amount: 200 * 100,
        currency: "INR",
        name: "iBuzoo",
        description: "Transaction",
        first: "Transaction",
        image: "",
        order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          onsuccess(response.razorpay_payment_id);
          console.log(response.razorpay_payment_id);
          console.log(response.razorpay_order_id);
          console.log(response.razorpay_signature);
          // alert(response.data.response.signatureIsValid);
          console.log(response);
        },
        prefill: {
          name: "jatin",
          email: "jatin@gmail.com",
          contact: "6397823778",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#aqua-primary",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        setTimeout(() => {
          // setpaymentsuccess(false);
          // navigate("/add_wallet");
        }, 3000);
        // SendpaymentData("Payment Not Successfull");
        // alert(response.error.code);
        // setstatus(false);
        // setpaymentsuccess(true);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);

        console.log(response);
      });
      rzp1.open();
    } else {
      alert("Enter Valid Amount");
    }
  };

  const onsuccess = (id) => {
    navigate("/booking");
  };
  useEffect(() => {
    get_state_fn();
  }, []);
  const [States, setStates] = useState([]);
  const [Citys, setCitys] = useState([]);
  const get_state_fn = async () => {
    try {
      const res = await get_state();
      if (res.data.status) {
        setStates(res.data.data.states);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  const get_city_fn = async (data) => {
    setstatevalue(data);
    try {
      const res = await get_city(data);
      if (res.data.status) {
        setCitys(res.data.data.cities);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };

  const addressdelete = async (id) => {
    setisloading(true);
    try {
      const res = await deleteaddress_api(id);
      if (res.data.status) {
        console.log(res);
        getalladress();
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  return (
    <>
      <div className="container mt-4 mb-4">
        <span onClick={() => navigate(-1)}>
          <button className={s["arrow_back"]}>
            <AiOutlineArrowLeft /> Back
          </button>
        </span>
        <div className={s["delevery_addres"]}>
          <div className="layout-container">
            <div className={s["address_left"]}>
              <div className={s["address_bg"]}>
                <div className={s["number_add"]}>1</div>
                <div className="delevry_heading">Service Address</div>
              </div>
              <div className="pt-3">
                {alladresslist?.map((data, index) => (
                  <div className="form-check mb-2" style={{ borderBottom: "1px solid #878787" }}>
                    <div className="d-flex" style={{ justifyContent: "space-between" }}>
                      <div>
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio1"
                          name="optradio"
                          checked={value === data?._id ? "checked" : ""}
                          value={data?._id}
                          onClick={(e) => datachecked(data)}
                        />
                        {data?.name}
                        <label className={`form-check-label ${s["user_home"]}`} for="radio1">
                          {data?.address_type} <span>{data?.phone}</span>
                        </label>
                      </div>
                      <div style={{ color: "red", cursor: "pointer" }} onClick={() => addressdelete(data._id)}>
                        <AiFillDelete />
                      </div>
                    </div>
                    <div className="mb-3">
                      Address-{data?.building} ,{data?.state} ({data?.city}), {data?.location} Landmark-{data?.landmark} ,Pincode-{data?.pincode}{" "}
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <div className="address_bg p-2">
                  <h3 className="" onClick={() => setexpandbox(!expandbox)} style={{ cursor: "pointer" }}>
                    <span className="number_add">
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </span>
                    <span className={s["delevry_heading"]}>
                      <IoAdd />
                      Add a new address
                    </span>
                  </h3>
                </div>
                <Expand open={expandbox}>
                  <div className="row">
                    <div className="col-md-6 mt-2">
                      <input type="text" placeholder="name" name="name" className="form-control" value={addAddress.name} onChange={(e) => useraddress(e)} />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="number"
                        placeholder="phone no"
                        name="phone"
                        className="form-control"
                        value={addAddress.phone}
                        onChange={(e) => useraddress(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mt-2">
                      <select
                        class="form-control"
                        value={statevalue}
                        onChange={(e) => {
                          setcityvalue("");

                          get_city_fn(e.target.value);
                        }}
                      >
                        <option>Select State</option>
                        {States.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mt-2">
                      <select class="form-control" value={cityvalue} onChange={(e) => setcityvalue(e.target.value)}>
                        <option>Select City</option>
                        {Citys?.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <textarea
                        type="text"
                        placeholder="Building"
                        name="address"
                        className="form-control"
                        value={addAddress.address}
                        onChange={(e) => useraddress(e)}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="text"
                        placeholder="landmark"
                        name="landmark"
                        className="form-control"
                        value={addAddress.landmark}
                        onChange={(e) => useraddress(e)}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <input
                        type="number"
                        placeholder="Pin Code"
                        name="pincode"
                        className="form-control"
                        value={addAddress.pincode}
                        onChange={(e) => useraddress(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <button className={`${s["btn-layout"]} ${s["btn-layout-main"]}`} onClick={() => adduseradress()}>
                        Save And Service Here
                      </button>
                    </div>
                  </div>
                </Expand>
              </div>
              <div className={["address_left"]} style={{ borderTop: "2px solid #777" }}>
                <div className={s["address_bg"]} style={{ marginTop: "1rem" }}>
                  <div className={s["number_add"]}>2</div>
                  <div className="delevry_heading"> Service Slot</div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <input
                        type="datetime-local"
                        value={date}
                        onChange={(e) => {
                          setdate(e.target.value);
                          setbooking_id("");
                        }}
                        className="form-control"
                        min={new Date().toISOString().replace(/T.*/, "")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <CouponsDiscount
                serviceid={location.state._id}
                finalprice={finalprice}
                onsubmit={(data) => {
                  setdiscountprice(data.discount_amount);
                  setgst(data.gst);
                  setcode(data.code);
                }}
              />
            </div>
          </div>
          <div>
            <div className={s["address_right"]}>
              <div className="product_details">
                <span>Price details</span>
              </div>
              <div className="product_details">
                <div className="product_details">
                  <div className="d-flex" style={{ justifyContent: "space-between" }}>
                    <div>Service Name:</div>
                    <div>{finalprice.name}</div>
                  </div>
                </div>
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                  <div>Service Mode :</div>
                  <div style={{ fontWeight: "bold", textTransform: "uppercase" }}>ONLINE</div>
                </div>
              </div>
              <div className="product_details">
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                  <div>Service Charges:</div>
                  <div>
                    <div>{finalprice.paying_amount}</div>
                  </div>
                </div>
              </div>
              <div className="product_details">
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                  <div>Estimated GST Charges:</div>
                  <div>
                    <div>{gst}</div>
                  </div>
                </div>
              </div>
              {discountprice && (
                <div className="product_details">
                  <div className="d-flex" style={{ justifyContent: "space-between" }}>
                    <div>Discount Price:</div>
                    <div>
                      <div>
                        <span style={{ color: "blue" }}> - {discountprice}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex" style={{ justifyContent: "space-between" }}>
                <div>
                  <h5>Total Payable</h5>
                </div>
                <div>{<h4>₹ {Number(finalprice.paying_amount) + Number(gst) - Number(discountprice)}</h4>}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "end", marginBlock: "1rem" }}>
                <button
                  className={`${s["btn-layout"]} ${s["btn-layout-main"]}`}
                  onClick={() => {
                    booking_id ? procesedtopayment() : adddetails();
                  }}
                >
                  Pay ₹ {Number(finalprice.paying_amount) + Number(gst) - Number(discountprice)}
                  <AiOutlineArrowRight size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loder loading={isloading} />
    </>
  );
};

export default Checkout;
