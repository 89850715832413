import React, { useEffect } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import HomeBanner from "../Components/Home/HomeBanner";
import Topservices from "../Components/Home/Topservices";
import RecentServices from "../Components/Home/RecentServices";
import WhyChoose from "../Components/Home/WhyChoose";
import TopCategory from "../Components/Home/TopCategory";
import NavbarTop from "../Common/Header/NavbarTop";
import Category from "../Components/Home/Category";
import Subcategories from "../Components/DialogBox/Subcategories";
import OurClient from "../Components/Home/OurClient";
const HomePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <NavbarTop />
      <Header />
      <HomeBanner />
      <Category />
      {/* <Topservices /> */}
      <RecentServices />
      <TopCategory />
      <WhyChoose />
      {/* <OurClient /> */}
      <Footer />
    </>
  );
};

export default HomePage;
