import React, { useContext, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import s from "./DialogBox.module.css";
import Custombutton from "../../Common/Button/CustomButton";
import Logo from "../../assets/Logo/LOGO.png";
import login_api from "../api/auth";
import { UserContext } from "../../App";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LoginPopup = ({ open, onclose }) => {
  const [phone, setphone] = useState("");
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [terms, setterms] = useState(true);

  const Proceedtologin = async () => {
    if (!phone) {
      toast.warn("Please enter a phone number");
      return;
    }
    if (!terms) {
      toast.warn("Please agree with Terms and Conditions");
      return;
    }
    setisloading(true);
    let temp = {
      phone: phone,
    };
    try {
      const res = await login_api(temp);
      if (res.data.status) {
        console.log(res);
        toast.success(res.data.message);
        if (res.data.status) {
          dispatch({
            type: "USER",
            payload: {
              ...state,
              userdetails: res.data.data,
            },
          });
        }

        dispatch({
          type: "LOGIN_CLOSE_POPUP",
        });
        dispatch({
          type: "OTP_VERIFY_OPEN_POPUP",
        });
        setisloading(false);
        setphone("");
        setterms(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };
  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true} onClose={() => onclose()}>
        <DialogTitle className="dialog_title" style={{ padding: "0rem" }}>
          <div className={s["logo-dialogbox"]}>
            <img src={Logo} alt="logo" />
          </div>
        </DialogTitle>
        <DialogContent className="sp_states_popup">
          <div>
            <h4>Login Now</h4>
          </div>
          <div className={s["login-form-container"]}>
            <label>Phone Number</label>
            <div className="" style={{ display: "flex", alignItems: "center" }}>
              <div className="" style={{ paddingRight: "0.2rem" }}>
                +91 <span style={{ color: "#8C64B4" }}>|</span>{" "}
              </div>
              <div style={{ flexGrow: "1" }}>
                <input
                  type="text"
                  value={phone}
                  autoFocus="true"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") Proceedtologin();
                  }}
                  onChange={(e) => {
                    if (isNaN(e.target.value) || e.target.value.includes("e") || e.target.value.includes(".") || e.target.value.includes("-")) return;
                    if (e.target.value.length > 10) return;
                    setphone(e.target.value.trim());
                  }}
                  style={{ border: "none", outline: "none", width: "100%", fontSize: "15px" }}
                />
              </div>
            </div>
          </div>
          <div className={s["select-checkbox-btn"]}>
            <input type="checkbox" id="css" name="terms" checked={terms ? true : false} onChange={(e) => setterms(e.target.checked)} />
            <label for="css">
              I have read{" "}
              <span
                style={{ color: "#0050CD", cursor: "pointer" }}
                onClick={() => {
                  dispatch({
                    type: "LOGIN_CLOSE_POPUP",
                  });
                  navigate("/terms-of-service");
                }}
              >
                Terms & Conditions
              </span>
            </label>
          </div>
          <div className="submit-btn" onClick={() => Proceedtologin()}>
            <Custombutton loading={isloading} text="Send OTP" />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginPopup;
