import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "./../utils";

const login = getBaseUrl() + "api/user/login";
const verify_otp = getBaseUrl() + "api/user/verify_otp";
const signup = getBaseUrl() + "api/user/signup";

export const signup_api = async (data) => {
  let config = {
    method: "post",
    url: signup,
    data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const verify_otp_api = async (data) => {
  let config = {
    method: "post",
    url: verify_otp,
    data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const login_api = async (data) => {
  let config = {
    method: "post",
    url: login,
    data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default login_api;
