import React from "react";
import BookingList from "../Components/Checkout/BookingList";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";

const BookingListPage = () => {
  return (
    <>
      <Header />
      <BookingList />
      <Footer />
    </>
  );
};

export default BookingListPage;
