import React from "react";
import loderimage from "./../../assets/Banner/loderimage.png";
const Loader = ({ loading }) => {
  return (
    <div className={`preloader ${loading ? "" : "preloader-deactivate"}`}>
      <div className="d-table">
        <div className="d-table-cell">
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
