import React from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { venderregister_api, vendor_bank_details_api, vendor_profile_update_api, vendorprofile_api } from "../api/vender";
import Header2 from "../../Common/Header/Header2";
import s from "./vender.module.css";
import { useEffect } from "react";
import { get_city, get_state } from "../api/profile";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { useContext } from "react";

const VendorProfile = () => {
  const navigate = useNavigate();
  const [stateuser, setStateUser] = useState([]);
  const { state, dispatch } = useContext(UserContext);
  const [isloading, setisloading] = useState(false);
  const [cityuser, setCityUser] = useState([]);
  const [userdata, setuserdata] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    building: "",
    street: "",
    locality: "",
    city: "",
    state: "",
    country: "INDIA",
    gstNo: "",
    panNo: "",
    aadharNo: "",
  });

  useEffect(() => {
    vendorprofile();
  }, []);
  const vendorprofile = async () => {
    setisloading(true);
    try {
      const res = await vendorprofile_api();
      if (res.data.status) {
        const { phone, name, email, address, company, building, street, locality, gst_no, pan_no, aadhar_no, state_id } = res?.data?.data?.vendor;
        setuserdata({
          phone: phone,
          name: name,
          email: email,
          address: address,
          company: company,
          building: building,
          street: street,
          locality: locality,
          city: "",
          state: state_id,
          country: "INDIA",
          gstNo: gst_no,
          panNo: pan_no,
          aadharNo: aadhar_no,
        });
        dispatch({
          type: "USER",

          payload: {
            ...state,
            vendor_data: res?.data?.data?.vendor,
          },
        });
        const data = res.data?.data?.vendor;
        console.log(res);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const get_state_fn = async () => {
    try {
      const res = await get_state();
      if (res.data.status) {
        setStateUser(res.data.data.states);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  const get_city_fn = async () => {
    try {
      const res = await get_city(userdata.state);
      if (res.data.status) {
        setCityUser(res.data.data.cities);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };

  useEffect(() => {
    get_city_fn();
  }, [userdata.state]);

  useEffect(() => {
    get_state_fn();
  }, []);
  const userinfofun = (e) => {
    setuserdata({ ...userdata, [e.target.name]: e.target.value });
  };

  const formsubmit = async () => {
    const temp = {
      name: userdata.name,
      address: userdata.address,
      company: userdata.company,
      building: userdata.building,
      street: userdata.street,
      locality: userdata.locality,
      city: userdata.city,
      state: userdata.state,
      country: userdata.country,
      gst_no: userdata.gstNo,
      pan_no: userdata.panNo,
      aadhar_no: userdata.aadharNo,
    };
    try {
      const res = await vendor_profile_update_api(temp);
      if (res.data.status) {
        toast.success(res.data.message);
        vendorprofile();
        setuserdata({
          ...userdata,
          phone: "",
          name: "",
          email: "",
          address: "",
          company: "",
          building: "",
          street: "",
          locality: "",
          city: "",
          state: "",
          country: "",
          gst_no: "",
          pan_no: "",
          aadhar_no: "",
        });
      } else {
        toast.warn(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Header2 />
      <div classNameName={s["main"]}>
        <div className="container">
          <section className={s["signup"]}>
            <h4 className={s["form-title"]} style={{ textAlign: "center", paddingBlock: "1rem" }}>
              Edit Your Profile
            </h4>

            <div className={s["signup-content"]}>
              <div className="left-signup-form">
                <div className="d-flex" style={{ alignItems: "center", gap: "1rem" }}>
                  <div class="form-group mt-2" style={{ flexGrow: "1" }}>
                    <label for="exampleInputPassword1">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      value={userdata.phone}
                      disabled
                      onChange={(e) => userinfofun(e)}
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="Enter phone"
                    />
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={userdata.name}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter name"
                  />
                </div>

                <div class="form-group mt-2">
                  <label for="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    name="email"
                    disabled
                    value={userdata.email}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">Company Name</label>
                  <input
                    type="text"
                    name="company"
                    value={userdata.company}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter company"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">Building Name</label>
                  <input
                    type="text"
                    name="building"
                    value={userdata.building}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter building"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">Street</label>
                  <input
                    type="text"
                    name="street"
                    value={userdata.street}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter street"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">Locality</label>
                  <input
                    type="text"
                    name="locality"
                    value={userdata.locality}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter locality"
                  />
                </div>
              </div>
              <div className={s["signup-form"]}>
                <div className="form-group mt-2">
                  <label htmlFor="state">State</label>
                  <select name="state" onChange={(e) => userinfofun(e)} className="form-control" id="state">
                    <option selected>Choose state</option>
                    {stateuser?.map((elem, id) => {
                      return <option value={elem._id}>{elem.name}</option>;
                    })}
                  </select>
                </div>

                <div className="form-group mt-2">
                  <label htmlFor="state">City</label>
                  <select name="city" onChange={(e) => userinfofun(e)} className="form-control" id="state">
                    <option selected>Choose city</option>
                    {cityuser?.map((elem, id) => {
                      return <option value={elem._id}>{elem.name}</option>;
                    })}
                  </select>
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">Country</label>
                  <input
                    type="text"
                    name="country"
                    disabled
                    value={userdata.country}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter country"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">GST No.</label>
                  <input
                    type="text"
                    name="gstNo"
                    value={userdata.gstNo}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter gst number"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">PAN No.</label>
                  <input
                    type="text"
                    name="panNo"
                    value={userdata.panNo}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter pan number"
                  />
                </div>
                <div class="form-group mt-2">
                  <label for="exampleInputPassword1">Aadhar No.</label>
                  <input
                    type="text"
                    name="aadharNo"
                    value={userdata.aadharNo}
                    onChange={(e) => userinfofun(e)}
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter aadhar number"
                  />
                </div>
              </div>
            </div>

            <div className="form-group form-button" style={{ textAlign: "center" }} onClick={() => formsubmit()}>
              <input type="submit" name="signup" id="signup" className={s["form-submit"]} value="Update" />
            </div>
          </section>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="footer-copyright-wrapper">
          <p class="footer-copyright-text">
            <a class="footer-copyright-link" href="#" target="_self">
              ©2023. | CLOUDGEM P.V.T L.T.D. All Rights Reserved | All rights reserved.{" "}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default VendorProfile;
