import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import s from "./home.module.css";
import { useNavigate } from "react-router-dom";
import Subcategories from "../DialogBox/Subcategories";
import { fetch_category, fetch_sub_category } from "../api/Category";
import Loder from "../Loder/Loder";

const Category = () => {
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [category, setCategory] = useState([1, 2]);
  const [categoryId, setCategoryId] = useState("");
  const [isloading, setisloading] = useState(false);
  const [allsubcategorybyid, setallsubcategorybyid] = useState([]);
  const handleCanvas = async (categoryId) => {
    try {
      let res = await fetch_sub_category(categoryId);
      if (res.data.status) {
        console.log(res.data.data.length);
        setallsubcategorybyid(res.data.data);
        if (res.data.data.length <= 0) {
          navigate(`/category-service-page?id=${categoryId}`);
        } else {
          setCanvasOpen(!canvasOpen);
        }
      }
    } catch (error) {
      console.log("error");
    }
  };
  const navigate = useNavigate();

  const fetchCategoryList = async () => {
    setisloading(true);
    try {
      let res = await fetch_category();
      if (res.data.status) {
        setisloading(false);
        setCategory(res.data.data);
      }
    } catch (error) {
      setisloading(false);
      console.log("error");
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const options = {
    loop: true,
    nav: true,
    autoplay: true,
    // rewind: true,
    mouseDrag: true,
    margin: 10,
    center: false,
    callback: true,
    dots: false,
    slidetransition: "linear",
    navText: ["<p><</p>", "<p>></p>"],
    autoplaySpeed: 4500,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      768: {
        items: 4,
      },
      992: {
        items: 4,
      },
      1200: {
        items: 6,
      },
    },
  };

  return (
    <>
      <div class="container section-title mt-5">
        <h3>Category We Serve</h3>
        <div className={s.crousal_container_main}>
          <div className="">
            <OwlCarousel callback={true} className="client-wrap owl-carousel owl-theme" {...options}>
              {category.map((data, i) => {
                return (
                  <div
                    className={s.services}
                    style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}
                    onClick={() => handleCanvas(data._id, data.sub_categories)}
                  >
                    <img src={data.image} style={{ width: "40%" }} />
                    <p>{data.name}</p>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
        <Subcategories props={canvasOpen} setCanvasOpen={setCanvasOpen} data={allsubcategorybyid} />
      </div>
      <Loder loading={isloading} />
    </>
  );
};

export default Category;
