import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import s from "./DialogBox.module.css";
import Custombutton from "../../Common/Button/CustomButton";
import Logo from "../../assets/Logo/LOGO.png";
import { toast } from "react-toastify";
import { cancle_booking_reason_api } from "../api/checkout";
import Loder from "../Loder/Loder";

const BookinCancleRegion = ({ open, onclose, onsubmit, cancelbookingid }) => {
  const [isloading, setisloading] = useState(false);
  const [reason, setreason] = useState("");

  const adddetails = async () => {
    if (reason == "") return toast.error("Please Enter Reason For Cancellation !");
    setisloading(true);
    let temp = {
      reason: reason,
    };
    try {
      const res = await cancle_booking_reason_api(cancelbookingid, temp);
      if (res.data.status) {
        console.log(res);
        toast.success(res.data.message);
        setreason("");
        onclose();
        onsubmit();
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true} onClose={() => onclose()}>
        <DialogTitle className="dialog_title" style={{ padding: "0rem" }}>
          <div className={s["logo-dialogbox"]}>
            <img src={Logo} alt="logo" />
          </div>
        </DialogTitle>
        <DialogContent className="sp_states_popup">
          <div>
            <h4 style={{ textAlign: "center" }}>Cancel Booking ?</h4>
          </div>
          <div className={s["login-form-container"]}>
            <label>Reason For Cancellation</label>
            <div className="">
              <div>
                <textarea
                  rows={3}
                  type="text"
                  value={reason}
                  onChange={(e) => setreason(e.target.value)}
                  autoFocus="true"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="submit-btn" onClick={() => adddetails()}>
            <Custombutton text="Cancel Booking" loading={isloading} />
          </div>
        </DialogContent>
      </Dialog>
      <Loder loading={isloading} />
    </>
  );
};

export default BookinCancleRegion;
