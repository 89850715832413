import React, { useEffect } from "react";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import VendorTermsofService from "../Components/MoreiBuzoo/VendorTermsofService";
const VendorTermsofServicePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <VendorTermsofService />
      <Footer />
    </>
  );
};

export default VendorTermsofServicePage;
