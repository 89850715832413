import React, { useEffect, useState } from "react";
import DataNotFound from "../../Common/DataNotFound";
import { toast } from "react-toastify";
import s from "./vender.module.css";
import { Card } from "react-bootstrap";
const Home = ({ homedata }) => {
  console.log("homedata", homedata);
  return (
    <div>
      {homedata && (
        <div className="dashboard-section" style={{ marginBlock: "2rem" }}>
          <div className={s["dashboard-header-container"]}>
            {homedata.length > 0 ? (
              homedata.map((data) => (
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h6>{data.name}</h6>
                        <h4>{data.count}</h4>
                      </div>
                    </div>
                  </div>
                </Card>
              ))
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
