import React, { useEffect, useState } from "react";
import Logo from "../../assets/Logo/Logofooter.png";
import { useNavigate } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { BiSolidPhone } from "react-icons/bi";
import { fetch_category, fetch_sub_category } from "../../Components/api/Category";
import Subcategories from "../../Components/DialogBox/Subcategories";
const Footer = () => {
  const navigate = useNavigate();
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [category, setCategory] = useState([1, 2]);
  const [categoryId, setCategoryId] = useState("");
  const [allsubcategorybyid, setallsubcategorybyid] = useState([]);
  const handleCanvas = async (categoryId) => {
    try {
      let res = await fetch_sub_category(categoryId);
      if (res.data.status) {
        console.log(res.data.data.length);
        setallsubcategorybyid(res.data.data);
        if (res.data.data.length <= 0) {
          navigate(`/category-service-page?id=${categoryId}`);
        } else {
          setCanvasOpen(!canvasOpen);
        }
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchCategoryList = async () => {
    const temp = {};
    try {
      let res = await fetch_category();
      if (res.data.status) {
        setCategory(res.data.data);
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);
  return (
    <div>
      <section class="pt-5 bg-black">
        <div class="container">
          <div class="row g-3 h-100">
            <div class="col-6 col-md-4 col-lg-4">
              <div class="address">
                <h6 class="mb-2 text-white">
                  <a class="navbar-brand text-white">
                    <img src={Logo} alt="" class="img-fluid" style={{ width: "50%" }} />
                  </a>
                </h6>
                <ul id="footer_location" class="list-unstyled">
                  <li class=" mb-1" style={{ cursor: "pointer" }} onClick={() => navigate("/about")}>
                    <a class="fs-12 text-white">About Us</a>
                  </li>
                  <li class="  mb-1" style={{ cursor: "pointer" }} onClick={() => navigate("/contact")}>
                    <a class="fs-12 text-white">Contact Us</a>
                  </li>
                  <li class="d-flex align-items-center   mb-2">
                    <BiSolidPhone size={20} style={{ color: "white", paddingRight: "1px" }} />
                    <a class="fs-12 text-white">
                      <a href="tel:+91 837 391 1193">+91-8373911193</a>
                    </a>
                  </li>
                  <li class="d-flex align-items-center   mb-2">
                    <AiOutlineMail size={20} style={{ color: "white", paddingRight: "1px" }} />
                    <a class="fs-12 text-white">
                      <a href="mailto:support@ibuzoo.com"> support@ibuzoo.com</a>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-6 col-md-4 col-lg-4">
              <div class="address">
                <h6 class="mb-2 text-white">Company</h6>
                <ul class="list-unstyled">
                  <li class=" mb-1" style={{ cursor: "pointer" }} onClick={() => navigate("/about")}>
                    <a class="fs-12 text-white">About Us</a>
                  </li>
                  {/* <li class="  mb-1">
                    <a href=" " class="fs-12 text-white">
                      Complaints
                    </a>
                  </li> */}
                  {/* <li class="  mb-1">
                    <a href=" " class="fs-12 text-white">
                      Career
                    </a>
                  </li> */}
                  <li class="  mb-1" style={{ cursor: "pointer" }} onClick={() => navigate("/privacy-policy")}>
                    <a class="fs-12 text-white">Privacy Policy</a>
                  </li>
                  <li class="mb-1" style={{ cursor: "pointer" }} onClick={() => navigate("/terms-of-service")}>
                    <a class="fs-12 text-white">Terms Of Services</a>
                  </li>
                  <li class="mb-1" style={{ cursor: "pointer" }} onClick={() => navigate("/vendor-terms-of-service")}>
                    <a class="fs-12 text-white">Terms & Conditions for vendors</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-6 col-md-4 col-lg-4">
              <div class="address">
                <h6 class="mb-2 text-white"> Category</h6>
                <ul class="list-unstyled">
                  {category?.slice(0, 7).map((data, index) => (
                    <li class="mb-1" key={index} style={{ cursor: "pointer" }} onClick={() => handleCanvas(data._id, data.sub_categories)}>
                      <a class="fs-12 text-white">{data.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <hr class="border border-white ms-auto  d-none d-lg-block" />
          </div>
        </div>
      </section>
      <footer class="py-4" style={{ backgroundColor: "#000" }}>
        <div class="container">
          <div class="row ">
            <div class=" col-12 col">
              <div class="d-flex justify-content-between align-items-center flex-column flex-lg-row">
                <p class="mb-0 text-white">© 2023 CLOUDGEM P.V.T L.T.D. All Rights Reserved</p>
                <a class="fs-12 text-white" style={{ cursor: "pointer" }} onClick={() => navigate("/privacy-policy")}>
                  Privacy Policy
                </a>
                <a class="fs-12 text-white" style={{ cursor: "pointer" }} onClick={() => navigate("/terms-of-service")}>
                  Term and Conditions for Users
                </a>

                <a class="fs-12 text-white" style={{ cursor: "pointer" }} onClick={() => navigate("/vendor-terms-of-service")}>
                  Term and Conditions for Vendors
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Subcategories props={canvasOpen} setCanvasOpen={setCanvasOpen} data={allsubcategorybyid} />
    </div>
  );
};

export default Footer;
