import React from "react";
import s from "./MoreiBuzoo.module.css";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
const VendorTermsofService = () => {
  return (
    <>
      <div className={s["termsof-container"]}>
        <BreadcrumbSection tittle="Vendor Terms of Service" />
        <div className={s["termsof-title"]}>
          <h1>Terms & Conditions for vendors</h1>
        </div>
        <div className="termsof-layout pb-5">
          <p>
            Welcome to iBuzoo-eServices, These terms and conditions outline the rules and regulations for vendors providing services on our platform. By
            registering as a vendor, you accept these terms in full. Please read these terms carefully before joining our platform.
          </p>
          <h4>1. Definitions:</h4>
          <p>
            <b>Platform:</b> Refers to iBuzoo-eServi
          </p>
          <p>
            <b>Vendor:</b> Refers to the individual or entity providing services on the platform.
          </p>
          <p>
            <b>Client:</b> Refers to the individual or entity seeking services on the platform.
          </p>
          <h4>2. Registration:</h4>
          <p>Vendors must provide accurate and complete information during the registration process.</p>
          <p>Vendor has to provide correct documents required during the registration process and complete the KYC process.</p>
          <p>
            Vendors are responsible for maintaining the confidentiality of their account information and agree to immediately inform us about any type of
            disclosure or unauthorised use of your account.
          </p>
          <p>
            You are solely responsible for all activities that take place through your account. We shall not be liable for any irrelevant activity or any
            unauthorised access to your account.
          </p>
          <h4>3. Services Offered:</h4>
          <p>Vendors are responsible for clearly defining the services they offer, including detailed descriptions, pricing, and any applicable terms.</p>
          <p>Vendors must adhere to the highest standards of professionalism and quality in delivering services.</p>
          <h4>4. Booking, Pricing and Payments:</h4>
          <p>
            Platform will assign you the services according to your specialisation and location Platform has only authority for setting their prices and clearly
            communicating them to clients.
          </p>
          <p>Payments will be processed through the platform, and vendors are subject to transaction fees as outlined in the platform's fee schedule.</p>
          <h4>7. Reviews and Ratings:</h4>
          <p>Clients may leave reviews and ratings for vendors.</p>
          <p>Vendors must maintain a high level of service quality to build positive reviews.</p>
          <h4>8. Intellectual Property:</h4>
          <p>Vendors retain the intellectual property rights to their work.</p>
          <p>Vendors grant iBuzoo-eServices a license to use, display, and promote their work on the platform.</p>
          <h4>9. Termination:</h4>
          <p>
            <b>iBuzoo-eServices </b>reserves the right to terminate a vendor's account for violations of these terms or for any reason deemed necessary.
          </p>
          <p>
            Vendors can terminate their accounts by providing written notice to <b>iBuzoo-eServices</b>.
          </p>
          <h4>10. Indemnification:</h4>
          <p>Vendors agree to indemnify iBuzoo-eServicesagainst any claims, damages, or liabilities arising from their services.</p>
          <h4>11. Changes to Terms:</h4>
          <p>
            <b>iBuzoo-eServicesreserves</b> the right to update or modify these terms at any time.
          </p>
          <p>Vendors will be notified of any changes, and continued use of the platform constitutes acceptance of the updated terms.</p>
          <p></p>
          <h4>12. Governing Law& Dispute resolution:</h4>
          <h5>1. Governing Law:</h5>
          <p>
            This agreement is governed by and shall be construed in accordance with the laws of the National Capital Territory of Delhi, India. Any legal
            actions or proceedings arising out of or relating to this agreement shall be subject to the exclusive jurisdiction of the courts located in Delhi.
          </p>
          <h5>2. Dispute Resolution:</h5>
          <p>
            In the event of any dispute, controversy, or claim arising out of or relating to this agreement or the breach, termination, or invalidity thereof,
            the parties shall use their best efforts to settle the dispute through direct negotiation, mediation, or other alternative dispute resolution
            methods before resorting to legal action.
          </p>
          <h6>2.1 Mediation:</h6>
          <p>
            If a dispute arises, the parties agree to attempt to resolve the dispute through mediation conducted by a mutually acceptable mediator. The costs of
            mediation shall be shared equally by both parties.
          </p>
          <h6>2.2 Arbitration:</h6>
          <p>
            If mediation does not result in a resolution, any unresolved dispute shall be referred to arbitration under the Arbitration and Conciliation Act,
            1996, or any statutory modification or re-enactment thereof. The arbitration proceedings shall be conducted by a single arbitrator appointed by
            mutual agreement or, failing such agreement, by the Chief Justice of the Delhi High Court.
          </p>
          <h6>2.3 Seat of Arbitration:</h6>
          <p>The seat of arbitration shall be Delhi, and the language of the arbitration shall be English.</p>
          <h6>2.4 Binding Nature of Arbitration Award:</h6>
          <p>
            The decision of the arbitrator shall be final and binding on both parties. The courts in Delhi shall have the exclusive jurisdiction to enforce the
            arbitration award.
          </p>
          <h6>2.6 Equitable Remedies:</h6>
          <p>
            Notwithstanding the above, either party may seek equitable remedies, including injunctive relief, from a court of competent jurisdiction to prevent
            irreparable harm or protect its intellectual property rights.
          </p>
          <h5>3. Compliance with Laws:</h5>
          <p>The parties agree to comply with all applicable laws and regulations concerning dispute resolution in Delhi.</p>
          <h6>13. Contact Information:</h6>
          <p>iBuzoo-eServices contact information for vendors: [Contact Information].</p>
          <p>By registering as a vendor on iBuzoo-eServicesyou acknowledge that you have read, understood, and agree to abide by these terms and conditions.</p>
          <h6>iBuzoo-eServices</h6>
          <p>
            <b>
              <a href="http://www.ibuzoo-eservices.com/" target="_blank">
                www.ibuzoo-eservices.com
              </a>
            </b>
          </p>
          <p>
            <b>+91-7011481834/8373912163</b>
          </p>
          <p>
            <b>01.11.2023</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default VendorTermsofService;
