import React, { useContext, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import s from "./DialogBox.module.css";
import Custombutton from "../../Common/Button/CustomButton";
import Logo from "../../assets/Logo/LOGO.png";
import OtpInput from "react-otp-input";
import { UserContext } from "../../App";
import { toast } from "react-toastify";
import { get_profile } from "../api/profile";
import { vender_login_verify_otp_api_api, vender_verify_otp_api } from "../api/vender";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const ProfessionalOtpVerify = ({ open, onclose, phone, type, onsubmit }) => {
  const [otp, setOtp] = useState("");
  const [isloading, setisloading] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [terms, setterms] = useState(false);
  const navigate = useNavigate();

  const registerotpverifyfun = async () => {
    setisloading(true);
    let temp = {
      phone: phone,
      otp: otp,
    };
    try {
      const res = await vender_verify_otp_api(temp);
      console.log(res.data.message);
      if (res.data.status) {
        onclose();
        setOtp("");
        onsubmit();
        setisloading(false);
        toast.success(res.data.message);
        setisloading(false);
        return;
      } else {
        toast.error(res.data.message);
        setisloading(false);
      }
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const login_otpverifyfun = async () => {
    setisloading(true);
    let temp = {
      phone: phone,
      otp: otp,
    };
    try {
      const res = await vender_login_verify_otp_api_api(temp);
      console.log(res.data.message);
      if (res.data.status) {
        onclose();
        setOtp("");
        onsubmit();
        Cookies.set("venderauth", "true", { secure: false }, { expires: 365 });
        Cookies.set("venderaccessToken", res.data.data.token, { secure: false }, { expires: 365 });

        navigate("/vender/dashboard");

        setisloading(false);
        toast.success(res.data.message);
        setisloading(false);
        return;
      } else {
        toast.error(res.data.message);
        setisloading(false);
      }
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true} onClose={() => onclose()}>
        <DialogTitle className="dialog_title" style={{ padding: "0rem" }}>
          <div className={s["logo-dialogbox"]}>
            <img src={Logo} alt="logo" />
          </div>
        </DialogTitle>
        <DialogContent className="sp_states_popup">
          <div>
            <h4>Verify OTP</h4>
          </div>
          <div className="" style={{ marginBlock: "1rem" }}>
            <OtpInput
              containerStyle={{ justifyContent: "space-between" }}
              inputStyle={{ width: "45px", height: "48px", fontSize: "20px", border: "1px solid rgba(0, 0, 0, 0.40)", borderRadius: "9px" }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <div className="submit-btn" onClick={() => (type === "register" ? registerotpverifyfun() : login_otpverifyfun())}>
            <Custombutton text="Send OTP" loading={isloading} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfessionalOtpVerify;
