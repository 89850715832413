import React from "react";
import DataNotFound from "../../Common/DataNotFound";

const Finance = () => {
  return (
    <div>
      <DataNotFound />
    </div>
  );
};

export default Finance;
