import React from "react";
import "./NavbarTop.css";
// import a from "../../assets/icons/india.png";
import { FaFacebook, FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { AiFillTwitterCircle, AiOutlineInstagram, AiOutlineMail, AiOutlineTwitter } from "react-icons/ai";
import { BsFacebook, BsFillEnvelopeAtFill, BsLinkedin, BsTelephone } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BiSolidPhone } from "react-icons/bi";
const NavbarTop = () => {
  return (
    <>
      {/* <!-- Header Top --> */}
      <header class="d-none d-lg-flex align-items-center " style={{ backgroundColor: "#000", paddingBlock: "0.5rem" }}>
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-12 col-md-5">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <AiOutlineMail size={20} style={{ color: "white", paddingRight: "1px" }} />

                  <span class="text-white fs-13">
                    <a href="mailto:info@ibuzoo.in"> info@ibuzoo.in</a>
                  </span>
                </div>
                <div>
                  <AiOutlineMail size={20} style={{ color: "white", paddingRight: "1px" }} />

                  <span class="text-white fs-13">
                    <a href="mailto:info.ibuzoo@gmail.com">info.ibuzoo@gmail.com</a>
                  </span>
                </div>
                <div>
                  <BiSolidPhone size={20} style={{ color: "white", paddingRight: "1px" }} />
                  <span class="text-white fs-13">
                    <a href="tel:+91 837 391 1193"> +91-8373911193</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="d-flex  align-items-center">
                <span class="text-white fs-13">Follow Us on</span>
                <a href="https://www.facebook.com" target="_blank" style={{ color: "white" }} className="mx-3">
                  <BsFacebook size={20} />
                </a>
                <a href="https://www.instagram.com" target="_blank" style={{ color: "white" }} className="mx-3">
                  <FaInstagramSquare size={20} />
                </a>
                <a href="https://twitter.com/Ibuzooeservices" target="_blank" style={{ color: "white" }} className="mx-3">
                  <AiFillTwitterCircle size={20} />
                </a>
                <a href="https://www.linkedin.com/showcase/ibuzoo-eservices/" target="_blank" style={{ color: "white" }} className="mx-3">
                  <FaLinkedin size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavbarTop;
