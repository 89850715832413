import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import s from "./DialogBox.module.css";
import Custombutton from "../../Common/Button/CustomButton";
import Logo from "../../assets/Logo/LOGO.png";
import { toast } from "react-toastify";
import { request_callback_api } from "../api/checkout";
import { fetch_category, fetch_sub_category, service_request_api } from "../api/Category";
import Cookies from "js-cookie";

const ServiceRequest = ({ open, onclose, service_id }) => {
  const [isloading, setisloading] = useState(false);
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [requirement, setrequirement] = useState("");
  const [industry, setindustry] = useState("");
  const [email, setemail] = useState("");
  const [Category, setCategory] = useState([]);
  const [allsubcategorybyArry, setallsubcategorybyArry] = useState([]);
  const [subcatid, setsubcatid] = useState("");
  const [Categoryid, setCategoryid] = useState("");
  const auth = Cookies.get("auth");

  const adddetails = async () => {
    if (!auth) {
      toast.warn("Please Login");
      return;
    }
    if (name == "") return toast.error("Please Enter Name !");
    if (phone == "") return toast.error("Please Enter Phone Number !");
    setisloading(true);
    let temp = {
      name: name,
      phone: phone,
      email: email,
      industry: industry,
      category: Categoryid,
      subcategory: subcatid,
      requirement: requirement,
    };
    try {
      const res = await service_request_api(temp);
      if (res.data.status) {
        console.log(res);
        toast.success(res.data.message);
        setname("");
        setphone("");
        setindustry("");
        setemail("");
        setrequirement("");
        setsubcatid("");
        setCategoryid("");
        onclose();
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const fetchCategoryList = async () => {
    const temp = {};
    try {
      let res = await fetch_category();
      if (res.data.status) {
        setCategory(res.data.data);
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const getsubcatbycatid = async (id) => {
    setCategoryid(id);
    try {
      let res = await fetch_sub_category(id);
      if (res.data.status) {
        setallsubcategorybyArry(res.data.data);
      }
    } catch (error) {
      console.log("error");
    }
  };
  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true} onClose={() => onclose()}>
        <DialogTitle className="dialog_title" style={{ padding: "0rem" }}>
          <div className={s["logo-dialogbox"]}>
            <img src={Logo} alt="logo" />
          </div>
        </DialogTitle>
        <DialogContent className="sp_states_popup">
          <div>
            <h4 style={{ textAlign: "center" }}>Fill your Details</h4>
          </div>

          <div className={s["login-form-container"]}>
            <label>Enter your Name</label>
            <div className="">
              <div>
                <input
                  type="text"
                  autoFocus="true"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Phone Number</label>
            <div className="">
              <div>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                  autoFocus="true"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Email</label>
            <div className="">
              <div>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  autoFocus="true"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Industry</label>
            <div className="">
              <div>
                <input
                  type="text"
                  value={industry}
                  onChange={(e) => setindustry(e.target.value)}
                  autoFocus="true"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className={s["login-form-container"]}>
            <label>Select Category</label>
            <div className="">
              <div>
                <select class="form-select" aria-label="Default select example" onChange={(e) => getsubcatbycatid(e.target.value)}>
                  <option>Select Category</option>
                  {Category?.map((data, index) => (
                    <option value={data._id}>{data.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Select SubCategory</label>
            <div className="">
              <div>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setsubcatid("");
                    setsubcatid(e.target.value);
                  }}
                >
                  <option>Select SubCategory</option>
                  {allsubcategorybyArry?.map((data, index) => (
                    <option value={data._id}>{data.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Enter your Requirement</label>
            <div className="">
              <div>
                <textarea
                  rows={3}
                  type="text"
                  value={requirement}
                  onChange={(e) => setrequirement(e.target.value)}
                  autoFocus="true"
                  spellCheck={true}
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="submit-btn" onClick={() => adddetails()}>
            <Custombutton text="Submit" />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ServiceRequest;
