import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/AboutPage";
import TermsofServicePage from "./Pages/TermsofServicePage";
import PrivacypolicyPage from "./Pages/PrivacypolicyPage";
import { createContext, useReducer } from "react";
import { initialState, reducer } from "./reducer/UserReducer";
import LoginPopup from "./Components/DialogBox/LoginPopup";
import OTPVeriyfPopup from "./Components/DialogBox/OTPVeriyfPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Components/utils/Loader";
import RegisterPopup from "./Components/DialogBox/RegisterPopup";
import ServicePage from "./Pages/ServicesPage";
import ServiceDetailPage from "./Pages/ServiceDetailPage";
import CategorybyservicePage from "./Pages/CategorybyservicePage";
import CartListPage from "./Pages/CartListPage";
import VenderLogin from "./Pages/VenderLogin";
import VenderHomePage from "./Pages/VenderHomePage";
import CheckoutPage from "./Pages/CheckoutPage";
import BookingListPage from "./Pages/BookingListPage";
import UserProfile from "./Components/UserProfile/UserProfile";
import VendorProfile from "./Components/Vender/VendorProfile";
import KycStatus from "./Components/Vender/KycStatus";
import UserHelp from "./Pages/UserHelp";
import InvoiceListPage from "./Pages/InvoiceListPage";
import ConsultCheckout from "./Components/Checkout/ConsultCheckout";
import ConsultCheckoutPage from "./Pages/ConsultCheckoutPage";
import ConsultListPage from "./Pages/ConsultListPage";
import Protected from "./Components/utils/ProtectedRoute";
import VendorTermsofServicePage from "./Pages/VendorTermsofServicePage";
import ContactPage from "./Pages/ContactPage";

export const UserContext = createContext();
window.toast = toast;
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log(state);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route exact path="/about" element={<AboutPage />}></Route>
        <Route exact path="/terms-of-service" element={<TermsofServicePage />}></Route>
        <Route exact path="/vendor-terms-of-service" element={<VendorTermsofServicePage />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacypolicyPage />}></Route>
        <Route exact path="/contact" element={<ContactPage />}></Route>
        {/* <Route exact path="/service" element={<ServicePage />}></Route> */}
        <Route exact path="/category-service-page" element={<CategorybyservicePage />}></Route>
        <Route exact path="/service-detail/:id" element={<ServiceDetailPage />}></Route>
        {/* <Route
          exact
          path="/cart"
          element={
            <Protected>
              <CartListPage />
            </Protected>
          }
        ></Route> */}

        <Route
          exact
          path="/checkout"
          element={
            <Protected>
              <CheckoutPage />
            </Protected>
          }
        ></Route>
        <Route
          exact
          path="/checkout2"
          element={
            <Protected>
              <ConsultCheckoutPage />
            </Protected>
          }
        ></Route>
        <Route
          exact
          path="/booking"
          element={
            <Protected>
              <BookingListPage />
            </Protected>
          }
        ></Route>
        <Route
          exact
          path="/invoice"
          element={
            <Protected>
              <InvoiceListPage />
            </Protected>
          }
        ></Route>
        <Route
          exact
          path="/consult"
          element={
            <Protected>
              <ConsultListPage />
            </Protected>
          }
        ></Route>
        <Route
          exact
          path="/profile"
          element={
            <Protected>
              <UserProfile />
            </Protected>
          }
        ></Route>
        <Route exact path="/vendor-profile" element={<VendorProfile />}></Route>
        <Route exact path="/kyc-status" element={<KycStatus />}></Route>
        <Route exact path="/contact" element={<UserHelp />}></Route>
        <Route exact path="/register" element={<VenderLogin />}></Route>
        <Route exact path="/vender/dashboard" element={<VenderHomePage />}></Route>
      </Routes>
      <LoginPopup
        open={state?.loginPopup}
        onclose={() =>
          dispatch({
            type: "LOGIN_CLOSE_POPUP",
          })
        }
      />
      <OTPVeriyfPopup
        open={state?.otpPopup}
        onclose={() =>
          dispatch({
            type: "OTP_VERIFY_CLOSE_POPUP",
          })
        }
      />
      <RegisterPopup
        open={state?.registerPopup}
        onclose={() =>
          dispatch({
            type: "REGISTER_CLOSE_POPUP",
          })
        }
      />
      <ToastContainer />

      {/* Preloader */}
      <Loader loading={state?.loading} />
    </UserContext.Provider>
  );
}

export default App;
