import axios from "axios";
import { getBaseUrl } from "./../utils";
import Cookies from "js-cookie";

const profile = getBaseUrl() + "api/user/profile";
const updateprofile = getBaseUrl() + "api/user/profile";

export const updateprofile_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };
  try {
    let res = await axios.patch(updateprofile, data, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const get_profile = async () => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.get(profile, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const get_state = async () => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.get(getBaseUrl() + "api/static/get_states", config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const get_city = async (state) => {
  console.log(state);
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
    };
    return await axios.get(getBaseUrl() + `api/static/get_cities?st_id=${state}`, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
