import React from "react";
import s from "./checkout.module.css";
import { booking_api } from "../api/checkout";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../../Common/DataNotFound";
import { consults_api } from "../api/consult";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
const ConsultList = () => {
  const [isloading, setisloading] = useState("");
  const navigate = useNavigate();
  const [consultarry, setconsultarry] = useState([]);
  useEffect(() => {
    getconsult_fun();
  }, []);

  const getconsult_fun = async () => {
    setisloading(true);
    try {
      const res = await consults_api();
      if (res.data.status) {
        setconsultarry(res?.data?.data?.consults);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  return (
    <>
      <div className="container">
        <BreadcrumbSection tittle="Consult List" />
        <div className={s["booking-list"]}>
          {consultarry?.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">S.no</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {consultarry?.map((data, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <img src={data?.service?.image} style={{ width: "70px", height: "50px" }} />
                    </td>
                    <td>{data?.service?.name}</td>
                    <td>{data?.consult_status}</td>
                    <td>{data?.scheduled_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <DataNotFound />
          )}
        </div>
      </div>
    </>
  );
};

export default ConsultList;
