import React, { useEffect, useState } from "react";
import s from "./home.module.css";
import OwlCarousel from "react-owl-carousel3";
import { AiOutlineArrowRight } from "react-icons/ai";
import a from "../../assets/Engineering Drawing Services/Electrical Drawings.png";
import b from "../../assets/Engineering Drawing Services/Mechanical Design.png";
import c from "../../assets/Engineering Drawing Services/Structure Drawings (Mechanical)...png";
import d from "../../assets/Engineering Drawing Services/Structure Drawings (Mechanical).png";
import { useNavigate } from "react-router-dom";
import { fetch_services_by_categoryId } from "../api/Category";
const WhyChoose = () => {
  const [CategoryServices, setCategoryServices] = useState([]);
  const navigate = useNavigate();

  const options = {
    loop: true,
    nav: false,
    autoplay: false,
    mouseDrag: true,
    margin: 40,
    center: false,
    dots: false,
    slidetransition: "linear",
    autoplaySpeed: 4500,
    responsive: {
      0: {
        items: 3,
      },
      768: {
        items: 5,
      },
      992: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    fetchCategoryServices();
  }, []);

  const fetchCategoryServices = async () => {
    try {
      let res = await fetch_services_by_categoryId("6523c647ea87d1fefa1db886");
      if (res.data.status) {
        setCategoryServices(res.data.data);
      }
    } catch (error) {
      console.log("error");
    }
  };
  return (
    <>
      {CategoryServices.length > 0 && (
        <div class="padding-top padding-bottom">
          <div class="container">
            <div class="row section-title">
              <div class="col-sm-6">
                <h3>Engineering Drawing Services</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 refitem text-center">
                <div className="">
                  <OwlCarousel className="client-wrap owl-carousel owl-theme" {...options}>
                    {CategoryServices?.map((data, index) => (
                      <div key={index} class="item" style={{ height: "100%", cursor: "pointer" }} onClick={() => navigate(`/service-detail/${data._id}`)}>
                        <a>
                          <div class="col-xs-12 bg-white text-center">
                            <img style={{ width: "70%" }} src={data.image} alt={data.name} />
                          </div>
                          <p>{data.name}</p>
                        </a>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WhyChoose;
