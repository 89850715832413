import React, { useContext, useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineHeart } from "react-icons/ai";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdEvent, MdOutlineForwardToInbox, MdOutlineHelp, MdVerified } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import Home from "./Home";
import Finance from "./Finance";
import Booking from "./Booking";
import Help from "./Help";
import RatingReviews from "./RatingReviews";
import Dropdown from "react-bootstrap/Dropdown";
import { vendor_home_data_api, vendorprofile_api } from "../api/vender";
import { toast } from "react-toastify";
import { UserContext } from "../../App";
import ServiceMessages from "./ServiceMessages";
import AdminMessages from "./AdminMessages";
import Invoices from "./Invoices";
import FinancialOverview from "./FinancialOverview";
import BankDetails from "./BankDetails";
import Gst from "./Gst";
import verify from "./../../assets/Icons/verify.gif";
import { IoMdArrowForward } from "react-icons/io";
import Loder from "../Loder/Loder";
const VendorHome2 = ({ open }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [vendordetails, setvendordetails] = useState("");
  const [isloading, setisloading] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [docstatus, setdocdtatus] = useState(false);
  const [Component, setComponent] = useState("");
  const { state, dispatch } = useContext(UserContext);
  const handleItemClick = (title, component) => {
    setComponent(component);
    setActiveItem(title);
  };

  const [menuItems, setmenuItems] = useState([
    {
      title: "Home",
      icons: <AiOutlineHome size={25} />,
      component: "Home",
      show: false,
      subItems: [],
    },
    {
      title: "Booking",
      icons: <MdEvent size={25} />,
      component: "Booking",
      show: false,
      subItems: [],
    },
    {
      title: "Inbox",
      icons: <MdOutlineForwardToInbox size={25} />,
      component: "",
      show: false,
      subItems: [
        {
          title: "Service Messages",
          component: "Services",
        },
        {
          title: "Admin Messages",
          component: "Messages",
        },
      ],
    },
    {
      title: "Finance",
      icons: <BiMoneyWithdraw size={25} />,
      component: "",
      show: false,
      subItems: [
        {
          title: "Invoices",
          component: "Invoices",
        },
        {
          title: "Financial overview",
          component: "FinancialOverview",
        },
        {
          title: "Bank details",
          component: "BankDetails",
        },
        {
          title: "Goods and Services Tax",
          component: "Gst",
        },
      ],
    },
    {
      title: "Help",
      icons: <MdOutlineHelp size={25} />,
      component: "Help",
      show: false,
      subItems: [],
    },
    {
      title: "Rating & reviews",
      icons: <AiOutlineHeart size={25} />,
      component: "RatingReviews",
      show: false,
      subItems: [],
    },
  ]);

  useEffect(() => {
    vendorprofile();
  }, []);
  const vendorprofile = async () => {
    setisloading(true);
    try {
      const res = await vendorprofile_api();
      if (res.data.status) {
        setvendordetails(res?.data?.data?.vendor);
        dispatch({
          type: "USER",
          payload: {
            ...state,
            vendor_data: res?.data?.data?.vendor,
          },
        });
        const data = res.data?.data?.vendor;
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const [homedata, sethomedata] = useState([
    {
      name: "Total Bookings",
      count: "0",
    },
    {
      name: "New Bookings",
      count: "0",
    },
    {
      name: "Open Bookings",
      conut: "0",
    },
    {
      name: "Inbox",
      count: "0",
    },
    {
      name: "Reviews",
      count: "0",
    },
  ]);

  useEffect(() => {
    vendorhomefunction();
  }, []);
  const vendorhomefunction = async () => {
    setisloading(true);
    try {
      const res = await vendor_home_data_api();
      if (res.data.status) {
        setdocdtatus(res.data.data.insufficient_docs);
        const { total_bookings, open_bookings, new_bookings, inbox, reviews } = res.data.data;

        sethomedata([
          {
            name: "Total Bookings",
            count: total_bookings,
          },
          {
            name: "New Bookings",
            count: new_bookings.length,
          },
          {
            name: "Open Bookings",
            conut: open_bookings.length,
          },
          {
            name: "Inbox",
            count: inbox.length,
          },
          {
            name: "Reviews",
            count: reviews.length,
          },
        ]);
        setisloading(false);
        return;
      }
      toast.danger(res.data.message);

      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.danger("Network error: ");
      setisloading(false);
    }
  };
  console.log(homedata);
  return (
    <>
      <section className="">
        <div className="d-flex justify-content-center p-4 ">
          {menuItems.map((data, i) => (
            <div className="mx-auto" key={i}>
              {data.title === "Inbox" || data.title === "Finance" ? (
                <Dropdown
                  className={`m-0 ${
                    activeItem === "Services" ||
                    activeItem === "Messages" ||
                    activeItem === "Invoices" ||
                    activeItem === "FinancialOverview" ||
                    activeItem === "BankDetails" ||
                    activeItem === "Gst"
                      ? "header-icon-select"
                      : "header-icon-hov"
                  }`}
                >
                  <Dropdown.Toggle className="p-0" variant="" id="inbox-dropdown">
                    <div className="text-center">{data.icons}</div>
                    <div className="text-center">{data.title}</div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {data.subItems.map((subItem, j) => (
                      <Dropdown.Item key={j} onClick={() => handleItemClick(subItem.title, subItem.component)}>
                        {subItem.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div
                  className={`m-0  ${activeItem === data.title ? "header-icon" : "header-icon-hov"}`}
                  onClick={() => {
                    handleItemClick(data.title, data.component);
                  }}
                >
                  <div className="text-center">{data.icons}</div>
                  <div className="text-center">{data.title}</div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="container">
          {vendordetails.kyc_status == "pending" && (
            <div style={{ display: "flex", justifyContent: "right" }}>
              <div className="kyc_stayus" onClick={() => navigate("/kyc-status")}>
                KYC Pending <IoMdArrowForward />
                {docstatus && <p style={{ fontSize: "11px" }}>Please upload all the documents.</p>}
              </div>
            </div>
          )}
          {vendordetails.kyc_status == "verified" && (
            <div style={{ display: "flex", justifyContent: "right" }}>
              <div
                className="kyc_stayus"
                style={{ backgroundColor: "white", color: "black", border: "1px solid #000" }}
                onClick={() => navigate("/kyc-status")}
              >
                KYC Verified <img style={{ width: "30px" }} src={verify} alt="verify" />
              </div>
            </div>
          )}

          {Component === "Home" ? (
            <Home homedata={homedata} />
          ) : Component === "Finance" ? (
            <Finance />
          ) : Component === "Booking" ? (
            <Booking />
          ) : Component === "Help" ? (
            <Help />
          ) : Component === "RatingReviews" ? (
            <RatingReviews />
          ) : Component === "Services" ? (
            <ServiceMessages />
          ) : Component === "Messages" ? (
            <AdminMessages />
          ) : Component === "Invoices" ? (
            <Invoices />
          ) : Component === "FinancialOverview" ? (
            <FinancialOverview />
          ) : Component === "BankDetails" ? (
            <BankDetails />
          ) : Component === "Gst" ? (
            <Gst />
          ) : (
            <Home homedata={homedata} />
          )}
        </div>
      </section>
      <Loder loading={isloading} />
    </>
  );
};

export default VendorHome2;
