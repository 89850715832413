import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import s from "./DialogBox.module.css";
import Custombutton from "../../Common/Button/CustomButton";
import Logo from "../../assets/Logo/LOGO.png";
import { UserContext } from "../../App";
import { signup_api } from "../api/auth";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { get_profile, get_state, get_city } from "../api/profile";

const RegisterPopup = ({ open, onclose }) => {
  const { state, dispatch } = useContext(UserContext);
  const [isloading, setisloading] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [stateuser, setStateUser] = useState("");
  const [cityUser, setCityUser] = useState("");
  const otp = localStorage.getItem("otp");

  const Proceedtologin = async () => {
    setisloading(true);
    const temp = {
      first_name: firstname,
      last_name: lastname,
      email: email,
      otp: otp,
      phone: state?.userdetails?.phone,
      state: stateuser,
      city: cityUser,
      address: address,
    };
    try {
      const res = await signup_api(temp);
      if (res.data.status) {
        Cookies.set(
          "auth",
          "true",
          { secure: false },
          // { sameSite: "Lax" },
          { expires: 365 }
        );
        Cookies.set(
          "accessToken",
          res.data.data.token,
          { secure: false },
          //  { sameSite: "Lax" },
          { expires: 365 }
        );
        toast.success(res.data.message);
        dispatch({
          type: "REGISTER_CLOSE_POPUP",
        });
        get_profile_fun();
        setisloading(false);
        localStorage.clear("otp");
        setfirstname("");
        setlastname("");
        setemail("");
        setaddress("");
        setStateUser("");
        setCityUser("");
        return;
      }
      toast.error(res.data.message);
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const get_profile_fun = async () => {
    try {
      const res = await get_profile();
      if (res.data.status) {
        dispatch({
          type: "USER",
          payload: {
            user_profile: res.data,
          },
        });
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  const get_state_fn = async () => {
    try {
      const res = await get_state();
      if (res.data.status) {
        setStates(res.data.data.states);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  const get_city_fn = async () => {
    try {
      const res = await get_city(stateuser);
      if (res.data.status) {
        console.log(res.data.data.cities);
        setCitys(res.data.data.cities);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };

  useEffect(() => {
    get_city_fn();
  }, [stateuser]);

  useEffect(() => {
    get_state_fn();
  }, []);

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true} onClose={() => onclose()}>
        <DialogTitle className="dialog_title" style={{ padding: "0rem" }}>
          <div className={s["logo-dialogbox"]}>
            <img src={Logo} alt="logo" />
          </div>
        </DialogTitle>
        <DialogContent className="sp_states_popup">
          <div>
            <h4 style={{ textAlign: "center" }}>Fill your Details</h4>
          </div>

          <div className={s["login-form-container"]}>
            <label>Enter your first name</label>
            <div className="">
              <div>
                <input
                  type="text"
                  autoFocus="true"
                  value={firstname}
                  onChange={(e) => setfirstname(e.target.value)}
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Enter your last name</label>
            <div className="">
              <div>
                <input
                  type="text"
                  autoFocus="true"
                  value={lastname}
                  onChange={(e) => setlastname(e.target.value)}
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Phone Number</label>
            <div className="">
              <div>
                <input
                  type="text"
                  value={state?.userdetails?.phone}
                  disabled
                  autoFocus="true"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Enter your Email Id</label>
            <div className="">
              <div>
                <input
                  type="email"
                  autoFocus="true"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Choose your state</label>
            <div className="">
              <div>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                  onChange={(e) => {
                    setStateUser(e.target.value);
                    setCityUser("");
                  }}
                >
                  <option selected>Choose state</option>
                  {states?.map((elem, id) => {
                    return <option value={elem._id}>{elem.name}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Choose your city</label>
            <div className="">
              <div>
                <select
                  className="form-select"
                  outline="false"
                  aria-label="Default select example"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                  onChange={(e) => setCityUser(e.target.value)}
                >
                  <option selected>Choose city</option>
                  {citys?.map((elem, id) => {
                    return <option value={elem._id}>{elem.name}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className={s["login-form-container"]}>
            <label>Enter your Address</label>
            <div className="">
              <div>
                <textarea
                  rows={3}
                  type="text"
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                  autoFocus="true"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="submit-btn" onClick={() => Proceedtologin()}>
            <Custombutton text="Submit" loading={isloading} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegisterPopup;
