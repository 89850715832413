import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { allocated_bookings_api, mail_payment_slip_api, update_booking_status_api } from "../api/vender";
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material";
import { Grid } from "@material-ui/core";
import VendorRejectBooking from "../DialogBox/VendorRejectBooking";
import DataNotFound from "../../Common/DataNotFound";

const AllocatedBookings = () => {
  const [allocatedbookings, setallocatedbookings] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [onsiteamountpopup, setonsiteamountpopup] = useState(false);
  const [booking_id, setbooking_id] = useState("");
  const [vendorrejectedpopup, setvendorrejectedpopup] = useState(false);
  const [status, setstatus] = useState("");
  const [service_charge, setservice_charge] = useState("");
  useEffect(() => {
    allocated_bookings();
  }, []);
  const allocated_bookings = async () => {
    setisloading(true);
    try {
      const res = await allocated_bookings_api();
      if (res.data.status) {
        console.log(res);
        setallocatedbookings(res.data.data.bookings);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const event_status = async (data, e) => {
    setbooking_id(data._id);
    setstatus(e.target.value);
    if (e.target.value === "delivered" && data.service_mode === "onsite") {
      setonsiteamountpopup(true);
      return;
    }
    if (e.target.value === "vendor_rejected") {
      setvendorrejectedpopup(true);
      return;
    }
    setisloading(true);
    let temp = {
      booking_id: data._id,
      status: e.target.value,
      service_charge: "",
    };
    try {
      let res = await update_booking_status_api(temp);
      if (res.data.status) {
        setisloading(false);
        allocated_bookings();
        toast.success(res.data.message);
      } else {
        setisloading(false);
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Notification !");
    }
  };

  const onsitedeleverd = async () => {
    setisloading(true);
    let temp = {
      booking_id: booking_id,
      status: status,
      service_charge: service_charge,
    };
    try {
      let res = await update_booking_status_api(temp);
      if (res.data.status) {
        setisloading(false);
        allocated_bookings();
        setbooking_id("");
        setservice_charge("");
        setstatus("");
        setonsiteamountpopup(false);
        toast.success(res.data.message);
      } else {
        setisloading(false);
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Notification !");
    }
  };

  const mail_payment_slip = async (booking_id) => {
    setisloading(true);
    try {
      let res = await mail_payment_slip_api(booking_id);
      if (res.data.status) {
        setisloading(false);
        toast.success(res.data.message);
      } else {
        setisloading(false);
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Notification !");
    }
  };

  return (
    <>
      <div className="container">
        <div className={["booking-list"]}>
          {allocatedbookings.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Scheduled Date</th>
                  <th scope="col">Service Mode</th>
                  <th scope="col">Status</th>
                  <th scope="col">Payment Slip</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {allocatedbookings.map((data, index) => (
                  <tr>
                    <td>
                      <img src={data?.service?.image} style={{ width: "70px", height: "50px" }} />
                    </td>
                    <td>{data?.service?.name}</td>
                    <td>{data?.scheduled_date}</td>
                    <td>{data?.service_mode}</td>
                    <td>{data?.booking_status}</td>
                    <td>
                      {data.vendor_payslip && (
                        <div style={{ display: "flex", gap: "0.5rem", cursor: "pointer" }}>
                          <a href={data?.vendor_payslip} className="button_outer" target="_blank">
                            View
                          </a>
                          <span className="button_outer" onClick={() => mail_payment_slip(data._id)} target="_blank">
                            Mail
                          </span>
                        </div>
                      )}
                    </td>
                    {data.booking_status === "vendor_rejected" ? (
                      <td>Rejected</td>
                    ) : (
                      <td>
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            style={{
                              color: `${
                                data.booking_status === "inprogress"
                                  ? "gray"
                                  : data.booking_status === "hold"
                                  ? "black"
                                  : data.booking_status === "delivered"
                                  ? "green"
                                  : "black"
                              }`,
                            }}
                            value={data.booking_status}
                            onChange={(e) => event_status(data, e)}
                          >
                            <option style={{ color: "black" }}>Select Status</option>
                            <option style={{ color: "black" }} value="inprogress">
                              Inprogress
                            </option>
                            <option style={{ color: "black" }} value="hold">
                              Hold
                            </option>
                            <option style={{ color: "black" }} value="delivered">
                              Delivered
                            </option>
                            {data.booking_status !== "delivered" && (
                              <option style={{ color: "black" }} value="vendor_rejected">
                                Rejected
                              </option>
                            )}
                          </select>
                        </div>
                      </td>
                    )}{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <DataNotFound />
          )}
        </div>
      </div>
      <Dialog open={onsiteamountpopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true">
        <DialogTitle>
          <div>Do you want to Add Amount?</div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label className="pb-1" for="exampleInputEmail1">
                  Enter Amount
                </label>
                <input
                  type="number"
                  value={service_charge}
                  className="form-control"
                  onChange={(e) => setservice_charge(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>
            </Grid>
          </Grid>
          <div style={{ display: "flex", justifyContent: "end", marginTop: "0.5rem", gap: "1rem" }}>
            <button type="button" class="btn btn-primary" onClick={() => onsitedeleverd()}>
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => {
                setservice_charge("");
                onsitedeleverd();
              }}
            >
              No
            </button>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <VendorRejectBooking
        open={vendorrejectedpopup}
        booking_id={booking_id}
        onsubmit={() => allocated_bookings()}
        onclose={() => setvendorrejectedpopup(!vendorrejectedpopup)}
      />
    </>
  );
};

export default AllocatedBookings;
