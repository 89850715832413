import React, { useEffect, useState } from "react";
import Custombutton from "../../Common/Button/CustomButton";
import { FiSearch } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { fetchAllbanner } from "../api/banner";
import { searchbycategory_api } from "../api/Category";
import { useNavigate } from "react-router-dom";
import ServiceRequest from "../DialogBox/ServiceRequest";
const HomeBanner = () => {
  const [isLoading, setisLoading] = useState(false);
  const [allbanner, setallbanner] = useState([]);
  const [CategoryServices, setCategoryServices] = useState([]);
  const [focus, setfocus] = useState(false);
  const [ServiceRequestpopup, setServiceRequestpopup] = useState(false);
  const navigate = useNavigate();
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchAllBannerFunc();
  }, []);

  const fetchAllBannerFunc = async () => {
    setisLoading(true);
    try {
      let res = await fetchAllbanner();
      if (res.data.status) {
        setallbanner(res.data.data.banners);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const searchbyservicefun = async (e) => {
    if (!search) {
      setfocus(false);
      return;
    }
    setfocus(true);
    const temp = {
      search: e.target.value,
    };
    try {
      let res = await searchbycategory_api(temp);
      if (res.data.status) {
        console.log(res);
        setCategoryServices(res.data.data);
      }
    } catch (error) {
      console.log("error");
    }
  };
  return (
    <div>
      <div class="">
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="true">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="" aria-label="Slide 1"></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              class="active"
              aria-current="true"
            ></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3" class=""></button>
          </div>
          <div class="carousel-inner">
            {allbanner.map((data, index) => (
              <div class={`carousel-item ${index === 0 && "active"}`}>
                <img src={data?.image} class="d-block w-100" alt="..." />
                <div class="carousel-caption d-none d-md-block">
                  <div className="row justify-content-start">
                    <div className="col-md-8" style={{ position: "relative" }}>
                      <div className="input-search-bar">
                        <FiSearch size={20} />
                        <input
                          type="text"
                          placeholder="Search services"
                          value={search}
                          onChange={(e) => {
                            setsearch(e.target.value);
                            searchbyservicefun(e);
                          }}
                        />
                        <RxCross2
                          size={20}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setfocus(false);
                            setsearch("");
                          }}
                        />
                      </div>
                      {focus && (
                        <div className="serch-box-banner">
                          {CategoryServices.length > 0 ? (
                            CategoryServices.map((data, index) => (
                              <p style={{ cursor: "pointer" }} onClick={() => navigate(`/service-detail/${data._id}`)} key={index}>
                                {data?.name}
                              </p>
                            ))
                          ) : (
                            <>
                              <p style={{ marginBlock: "1rem" }}>No Data Available</p>
                              <div
                                className="submit-btn"
                                onClick={() => {
                                  setfocus(false);
                                  setServiceRequestpopup(true);
                                }}
                              >
                                <Custombutton text="Service Request" />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <h1>{data?.title} </h1>
                  <p>Get a discount for everey service order! Only vaild for today!</p>
                </div>
              </div>
            ))}
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <ServiceRequest open={ServiceRequestpopup} onclose={() => setServiceRequestpopup(!ServiceRequestpopup)} />
    </div>
  );
};

export default HomeBanner;
