import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { get_invoices_vendor_api, upload_booking_invoice_api } from "../api/vender";
import DataNotFound from "../../Common/DataNotFound";

const Invoices = () => {
  const [invoicedeatis, setinvoicedeatis] = useState([]);
  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    allocated_bookings();
  }, []);
  const allocated_bookings = async () => {
    setisloading(true);
    try {
      const res = await get_invoices_vendor_api();
      if (res.data.status) {
        console.log(res);
        setinvoicedeatis(res.data.data.invoices);

        setisloading(false);
        return;
      }
      toast.danger(res.data.message);

      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.danger("Network error: ");

      toast.error("Error Notification !");
      setisloading(false);
    }
  };
  const profile_update_img = async (data, e) => {
    setisloading(true);
    const fd = new FormData();
    fd.append("invoice_image", e.target.files[0]);
    fd.append("booking_id", data._id);

    try {
      const res = await upload_booking_invoice_api(fd);
      if (res.data.status) {
        toast.success(res.data.message);
        allocated_bookings();
      } else {
        toast.error(res.data.message);
        console.log("data response error:::", res);
      }
      setisloading(false);
    } catch (error) {
      toast.error("Network error");
      console.log("data response error:::", error);
      setisloading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className={["booking-list"]}>
          {invoicedeatis.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">S.no</th>
                  <th scope="col">Image</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {invoicedeatis.map((data, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <img src={data?.invoice_image} style={{ width: "70px", height: "50px" }} />
                    </td>
                    <td>{data?.booking_status}</td>
                    <td>
                      <label htmlFor={`contained-button-file${index}`} class="dropdown-item">
                        Upload Invoice
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        id={`contained-button-file${index}`}
                        onChange={(e) => profile_update_img(data, e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <DataNotFound />
          )}
        </div>
      </div>
    </>
  );
};

export default Invoices;
