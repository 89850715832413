import React, { useEffect } from "react";
import About from "../Components/MoreiBuzoo/About";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import BreadcrumbSection from "./../Components/BreadcrumbSection/BreadcrumbSection";
const AboutPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <About />
      <Footer />
    </>
  );
};

export default AboutPage;
