import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/Logo/LOGO.png";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Cookies from "js-cookie";
import s from "./Header.module.css";
import { UserContext } from "../../App";

const Header2 = () => {
  const navigate = useNavigate();
  const auth = Cookies.get("venderauth");
  const { state, dispatch } = useContext(UserContext);
  return (
    <div>
      <nav class="navbar navbar-expand-lg bg-white px-4 shadow-sm">
        <div class="container flex-nowrap">
          <a class="navbar-brand text-white" onClick={() => navigate("/vender/dashboard")} style={{ cursor: "pointer" }}>
            <img src={Logo} alt="" class="img-fluid" style={{ width: "45%" }} />
          </a>

          <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="">
              <GiHamburgerMenu fontSize={"25px"} />
            </span>
          </button>
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" style={{ width: "300px" }}>
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                Hi Welcome iBuzoo
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body justify-content-end align-items-center">
              <ul class="navbar-nav   mb-2 mb-lg-0 align-items-lg-center">
                <li class="nav-item mx-1 ">
                  <a class="nav-link text-dark" style={{ cursor: "pointer" }}>
                    <span className={s.register_as_profession_btn}>{state?.vendor_data?.name} </span>
                  </a>
                </li>
              </ul>

              <div class="profile mx-lg-3 my-2 my-lg-0 nav-item dropdown">
                <a href="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {!auth || undefined ? (
                    ""
                  ) : (
                    <img
                      src={`https://eu.ui-avatars.com/api/?rounded=true&name=${state?.vendor_data?.name}&size=350?`}
                      alt=""
                      srcset=""
                      style={{ height: "50px" }}
                    />
                  )}
                </a>
                {auth && (
                  <ul class="dropdown-menu">
                    <li style={{ cursor: "pointer" }} onClick={() => navigate("/vendor-profile")}>
                      <a class="dropdown-item">Profile</a>
                    </li>
                    <li style={{ cursor: "pointer" }} onClick={() => navigate("/kyc-status")}>
                      <a class="dropdown-item">KYC Status</a>
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        Cookies.remove("venderauth");
                        Cookies.remove("venderaccessToken");
                        navigate("/");
                      }}
                    >
                      <a class="dropdown-item">Logout</a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header2;
