import React, { useState, useEffect, useContext } from "react";
import "./UserProfile.css";
import { Grid } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { get_city, get_profile, get_state, updateprofile_api } from "../api/profile";
import Header from "../../Common/Header/Header";
import Footer from "../../Common/Footer/Footer";
import { UserContext } from "../../App";
import { toast } from "react-toastify";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
import Loder from "../Loder/Loder";
const UserProfile = () => {
  const { state, dispatch } = useContext(UserContext);
  const [isUpdated, setisUpdated] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [gsterror, setgsterror] = useState(false);
  const [imagedata, setimagedata] = useState(null);
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [stateuser, setStateUser] = useState("");
  const [cityUser, setCityUser] = useState("");
  const navigate = useNavigate();
  const [userdataArry, setuserdataArry] = useState({
    first_name: "",
    last_name: "",
    state1: "",
    city: "",
    address: "",
    profile_image: "",
    number: "",
    email: "",
    gst_no: "",
  });

  const { first_name, last_name, state1, city, address, profile_image, email, number, gst_no } = userdataArry;

  useEffect(() => {
    get_profile_fun();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  /////api integration get user
  const get_profile_fun = async () => {
    setisloading(true);
    try {
      const res = await get_profile();
      if (res.data.status) {
        setStateUser(res?.data?.data?.state_id);
        setCityUser(res?.data?.data?.city_id);
        setuserdataArry({
          first_name: res?.data?.data?.first_name,
          last_name: res?.data?.data?.last_name,
          number: res?.data?.data?.phone,
          email: res?.data?.data?.email,
          address: res?.data?.data?.address,
          profile_image: res?.data?.data?.profile_image,
          gst_no: res?.data?.data?.gst_no,
        });
        dispatch({
          type: "USER",
          payload: {
            ...state,
            userdetails: res.data.data,
          },
        });
      } else {
        console.log("data response error:::", res);
        setisloading(false);
        toast.error(res.data.message);
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      setisloading(false);
      toast.error("Error Notification !");
    }
  };

  ////user update
  const userupdate = (e) => {
    setuserdataArry({
      ...userdataArry,
      [e.target.name]: e.target.value,
    });
  };

  /////api integration update user
  const profileupdate = async () => {
    if (gst_no) {
      if (!gsterror) return toast.error("GST Invalid! !");
    }
    setisloading(true);
    let temp = {
      first_name: first_name,
      last_name: last_name,
      state: stateuser,
      city: cityUser,
      gst_no: gst_no,
      address: address,
      profile_image: profile_image,
    };
    try {
      const res = await updateprofile_api(temp);
      if (res.data.status) {
        setisUpdated(!isUpdated);
        toast.success(res.data.message);
      } else {
        console.log("data response error:::", res);
        toast.error(res.data.message);
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const profile_update_img = async (e) => {
    setisloading(true);
    setimagedata(e.target.files);
    const fd = new FormData();
    fd.append("profile_image", e.target.files[0]);

    try {
      const res = await updateprofile_api(fd);
      if (res.data.status) {
        get_profile_fun();
      } else {
        console.log("data response error:::", res);
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      setisloading(false);
    }
  };

  const deleteprofilepic = async () => {};

  useEffect(() => {
    get_state_fn();
  }, []);

  useEffect(() => {
    get_city_fn();
  }, [stateuser]);

  const get_state_fn = async () => {
    try {
      const res = await get_state();
      if (res.data.status) {
        setStates(res.data.data.states);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  const get_city_fn = async () => {
    try {
      const res = await get_city(stateuser);
      if (res.data.status) {
        setCitys(res.data.data.cities);
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  const validateGSTIN = (input) => {
    // Check if the GSTIN is in the correct format
    if (/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/.test(input)) {
      setgsterror(true);
    } else {
      setgsterror(false);
    }
  };
  return (
    <>
      <Header />
      <div className="Contact_Page_padding">
        <div className="container">
          <BreadcrumbSection tittle="Profile" />
          <div className="card_section pb-5">
            <Card className="Card_shadow p-3 mt-5">
              <div className="text-center mt-5 mb-3">
                <h4> Edit Your Profile</h4>
              </div>
              <div className="user-profile-layout">
                <div className="user-image">
                  <img src={`${profile_image ? profile_image : `https://eu.ui-avatars.com/api/?name=${first_name}&rounded=true&size=100`}`} />
                  <a class="dropdown edit-icon-profile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <FiEdit2 />
                  </a>
                  <ul class="dropdown-menu">
                    <li style={{ cursor: "pointer" }}>
                      <label htmlFor="contained-button-file" class="dropdown-item">
                        Edit Profile
                      </label>
                      <input type="file" accept="image/*" style={{ display: "none" }} id="contained-button-file" onChange={(e) => profile_update_img(e)} />
                    </li>
                    <li style={{ cursor: "pointer" }}>
                      <label class="dropdown-item" onClick={() => deleteprofilepic()}>
                        Delete Profile
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card_admissiondetails_height">
                <div className="textfiled_margin">
                  <div class="container">
                    <div class="row">
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            placeholder="First Name"
                            value={first_name}
                            onChange={(e) => userupdate(e)}
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            placeholder="Last Name"
                            value={last_name}
                            onChange={(e) => userupdate(e)}
                          />
                        </div>
                      </div>

                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Email</label>
                          <input type="text" disabled className="form-control" name="email" placeholder="Email" value={email} />
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Mobile Number</label>
                          <input type="nember" disabled className="form-control" name="number" placeholder="Number" value={number} />
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label>Select State</label>
                          <div className="">
                            <div>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                value={stateuser}
                                onChange={(e) => {
                                  setStateUser(e.target.value);
                                  setCityUser("");
                                }}
                              >
                                <option selected>Choose state</option>
                                {states?.map((elem, id) => {
                                  return <option value={elem._id}>{elem.name}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label>Choose your city</label>
                          <div className="">
                            <div>
                              <select
                                className="form-select"
                                outline="false"
                                value={cityUser}
                                aria-label="Default select example"
                                onChange={(e) => {
                                  setCityUser(e.target.value);
                                }}
                              >
                                <option selected>Choose city</option>
                                {citys?.map((elem, id) => {
                                  return <option value={elem._id}>{elem.name}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">GST Number</label>
                          <input
                            style={{ textTransform: "uppercase" }}
                            type="text"
                            className="form-control"
                            name="gst_no"
                            placeholder="GST Number"
                            value={gst_no}
                            onChange={(e) => {
                              validateGSTIN(e.target.value);
                              userupdate(e);
                            }}
                          />
                          {gst_no.length > 7 && !gsterror && <div style={{ color: "red" }}>Invalid Phone. Please enter a valid Phone number.</div>}
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group p-1">
                          <label for="exampleInputEmail1">Address</label>
                          <textarea
                            rows={3}
                            onChange={(e) => userupdate(e)}
                            type="text"
                            className="form-control"
                            name="address"
                            placeholder="Address"
                            value={address}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <Grid className="Component_main_grid">
                    <Grid item md={6} sm={12} xs={12} className="p-1">
                      <div className="form-group">
                        <label for="exampleInputEmail1"></label>
                        <p className="note-text">
                          ⚠️Note: Please{" "}
                          <span style={{ cursor: "pointer", color: "#0b16ed" }} onClick={() => navigate("/contact")}>
                            contact the ibuzoo support team
                          </span>{" "}
                          to update your Mobile Number & Email ID.{" "}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <div className="text-center-button mt-1 ">
                    <button type="button" className="btn get_otp_btn_userprofile_btn" onClick={profileupdate}>
                      Update Profile
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
      <Loder loading={isloading} />
    </>
  );
};

export default UserProfile;
