import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "./../utils";

const create_consult = getBaseUrl() + "api/user/create_consult";
const init_consult_payment = getBaseUrl() + "api/user/init_consult_payment";
const consults = getBaseUrl() + "api/user/consults";

export const consults_api = async () => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };

  try {
    let res = await axios.get(consults, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const init_consult_payment_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };

  try {
    let res = await axios.post(init_consult_payment, data, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const create_consult_api = async (data) => {
  let config = {
    headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
  };

  try {
    let res = await axios.post(create_consult, data, config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default create_consult_api;
