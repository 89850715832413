import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import s from "./DialogBox.module.css";
import Custombutton from "../../Common/Button/CustomButton";
import Logo from "../../assets/Logo/LOGO.png";
import OtpInput from "react-otp-input";
import login_api, { verify_otp_api } from "../api/auth";
import { UserContext } from "../../App";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { get_profile } from "../api/profile";
const OTPVeriyfPopup = ({ open, onclose }) => {
  const [otp, setOtp] = useState("");
  const [isloading, setisloading] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [terms, setterms] = useState(false);

  const otpverifyfun = async () => {
    setisloading(true);
    const phone = state?.userdetails?.phone;
    const newUser = state?.userdetails?.newUser;

    let temp = {
      phone: phone,
      otp: otp,
      newUser: newUser,
    };
    try {
      const res = await verify_otp_api(temp);
      if (res.data.status) {
        if (!res.data.data.newUser) {
          Cookies.set(
            "auth",
            "true",
            { secure: false },
            //  { sameSite: "Lax" },
            { expires: 365 }
          );
          Cookies.set(
            "accessToken",
            res.data.data.token,
            { secure: false },
            // { sameSite: "Lax" },
            { expires: 365 }
          );
        }
        setisloading(false);
        toast.success(res.data.message);
        dispatch({
          type: "OTP_VERIFY_CLOSE_POPUP",
        });
        localStorage.setItem("otp", otp);
        if (res.data.data.newUser) {
          dispatch({
            type: "REGISTER_OPEN_POPUP",
          });
          setOtp("");
          setterms(false);
          return;
        }
        get_profile_fun();
        setisloading(false);
        setOtp("");
        setterms(false);
        return;
      }
      toast.error(res.data.message);
      setisloading(false);
      setOtp("");
      setterms(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const get_profile_fun = async () => {
    try {
      const res = await get_profile();
      if (res.data.status) {
        dispatch({
          type: "USER",
          payload: {
            user_profile: res.data,
          },
        });
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };

  //resend otp

  const getotpverify = async () => {
    if (!state?.userdetails?.phone) {
      toast.warn("Please enter a phone number");
      return;
    }

    let temp = {
      phone: state?.userdetails?.phone,
    };
    try {
      const res = await login_api(temp);
      if (res.data.status) {
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
    }
  };
  const [resend, setResend] = useState(false);
  const [second, setsecond] = useState(60);
  let min = (second / 60) | 0;
  let sec = second % 60 | 0;

  function resendOtp() {
    setResend(false);
    getotpverify();
    setsecond(60);
  }
  useEffect(() => {
    let ID = null;
    if (second <= 0) {
      return setResend(true);
    }
    ID = setInterval(() => {
      setsecond((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(ID);
  }, [second]);
  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth={true} onClose={() => onclose()}>
        <DialogTitle className="dialog_title" style={{ padding: "0rem" }}>
          <div className={s["logo-dialogbox"]}>
            <img src={Logo} alt="logo" />
          </div>
        </DialogTitle>
        <DialogContent className="sp_states_popup">
          <div>
            <h4>Verify OTP</h4>
          </div>
          <div className="" style={{ marginBlock: "1rem" }}>
            <OtpInput
              containerStyle={{ justifyContent: "space-between" }}
              inputStyle={{ width: "45px", height: "48px", fontSize: "20px", border: "1px solid rgba(0, 0, 0, 0.40)", borderRadius: "9px" }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <p style={{ paddingTop: "0.4rem" }}>
            Resend OTP available in{" "}
            {resend ? (
              <span style={{ color: "blue", cursor: "pointer" }} onClick={resendOtp}>
                Resend
              </span>
            ) : (
              `${String(min).padStart(2, "0")}:${String(sec).padStart(2, "0")}`
            )}
          </p>
          <div className="submit-btn" onClick={() => otpverifyfun()}>
            <Custombutton text="Send OTP" loading={isloading} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OTPVeriyfPopup;
