import React from "react";
import NavbarTop from "../Common/Header/NavbarTop";
import Header from "../Common/Header/Header";
import Categorybyservices from "../Components/Services/Categorybyservices";
import RecentServices from "../Components/Home/RecentServices";
import WhyChoose from "../Components/Home/WhyChoose";
import Footer from "../Common/Footer/Footer";

const CategorybyservicePage = () => {
  return (
    <div>
      <NavbarTop />
      <Header />
      <Categorybyservices />
      <RecentServices />
      <WhyChoose />
      <Footer />
    </div>
  );
};

export default CategorybyservicePage;
