import React, { useContext } from "react";
import { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { fetch_category, fetch_category_services, filterbycategory_api, searchbycategory_api } from "../api/Category";
import { add_cart, remove_cart } from "../api/cart";
import { toast } from "react-toastify";
import { UserContext } from "../../App";
import { get_profile } from "../api/profile";
import Cookies from "js-cookie";
import RequestCallback from "../DialogBox/RequestCallback";
import DataNotFound from "../../Common/DataNotFound";
import Loder from "../Loder/Loder";

const SubCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = Cookies.get("auth");
  const searchParams = new URLSearchParams(location.search);
  const { state, dispatch } = useContext(UserContext);
  const [Category, setCategory] = useState([]);
  const cId = searchParams.get("id");
  const sId = searchParams.get("sid");
  const [categoryServices, setCategoryServices] = useState([]);
  const [RequestCallbackpopup, setRequestCallbackpopup] = useState(false);
  const [service_id, setservice_id] = useState("");
  const [isloading, setisloading] = useState(false);
  const [filterservices, setfilterservices] = useState([]);
  const [search, setsearch] = useState("");
  const fetchCategoryServices = async () => {
    setisloading(true);
    const temp = {
      cId: cId,
      sId: sId,
    };
    try {
      let res = await fetch_category_services(temp);
      if (res.data.status) {
        setCategoryServices(res.data.data);
        setisloading(false);
      }
    } catch (error) {
      console.log("error");
      setisloading(false);
    }
  };

  useEffect(() => {
    fetchCategoryServices();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [sId]);

  function inCart(id) {
    if (!auth) {
      return false;
    }
    for (let i = 0; i < state?.user_profile?.data?.cart.length; i++) {
      const item = state?.user_profile?.data?.cart[i];
      if (item.service === id) return true;
    }
    return false;
  }
  const addtocartfun = async (data, index) => {
    if (!auth) {
      toast.warn("Please Login");
      return;
    }
    try {
      let res = await add_cart(data._id);
      if (res.data.status) {
        console.log(res);
        get_profile_fun();
      }
    } catch (error) {
      console.log("error");
    }
  };
  const remove_cart_fun = async (data, index) => {
    try {
      let res = await remove_cart(data._id);
      if (res.data.status) {
        console.log(res);
        get_profile_fun();
      }
    } catch (error) {
      console.log("error");
    }
  };

  const get_profile_fun = async () => {
    try {
      const res = await get_profile();
      if (res.data.status) {
        dispatch({
          type: "USER",
          payload: {
            ...state,
            user_profile: res.data,
          },
        });
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };

  const fetchCategoryList = async () => {
    setisloading(true);

    try {
      let res = await fetch_category();
      if (res.data.status) {
        setisloading(false);
        setCategory(res.data.data);
      }
    } catch (error) {
      setisloading(false);
      console.log("error");
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const filterbycategoryfun = (data, e) => {
    if (e.target.checked) {
      if (filterservices.includes(data._id)) {
        return;
      } else {
        filterservices.push(data._id);
        setfilterservices([...filterservices]);
      }
    } else {
      const filteredCount = filterservices.filter((item) => item !== data._id);
      setfilterservices(filteredCount);
    }
  };
  useEffect(() => {
    if (filterservices.length <= 0) return;
    filtercatfun();
  }, [filterservices]);
  const filtercatfun = async () => {
    setisloading(true);

    const temp = {
      categs: filterservices.toString(),
    };
    try {
      let res = await filterbycategory_api(temp);
      if (res.data.status) {
        setisloading(false);
        setCategoryServices(res.data.data);
      }
    } catch (error) {
      setisloading(false);
      console.log("error");
    }
  };
  const searchbyservicefun = async (e) => {
    setisloading(true);

    const temp = {
      search: e.target.value,
    };
    try {
      let res = await searchbycategory_api(temp);
      if (res.data.status) {
        setCategoryServices(res.data.data);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);

      console.log("error");
    }
  };
  return (
    <div className="container mt-5">
      <div class="search_box_astromall_new mt-5">
        <input
          type="search"
          name="productSearch"
          id="productSearch"
          class="mat-autocomplete-trigger ng-valid ng-touched ng-dirty"
          placeholder="Let's find what you're looking for..."
          role="combobox"
          aria-expanded="false"
          aria-haspopup="true"
          value={search}
          onChange={(e) => {
            setsearch(e.target.value);
            searchbyservicefun(e);
          }}
        />
        <i class="fa fa-search"></i>
      </div>
      <div class="row">
        <div class="col-12 col-md-5 col-lg-3">
          <div class="side_bar px-4 shadow-sm">
            <div class="sidebar_header d-flex align-items-center justify-content-between py-3 br-bottom">
              <h4 class="fs-19 mb-0 text-dark">Search Filter</h4>
              {/* <div class="ssh-header">
                <a id="service-click-1-all-clear" style={{ cursor: "pointer" }} class="clear_all fs-10 ft-medium text-dark">
                  Clear All
                </a>
              </div> */}
            </div>

            <div class="innerfilter">
              <h6 class="ft-medium text-dark">Category</h6>
              <ul class="list-unstyled">
                {Category.map((data, index) => (
                  <li class="form-check">
                    <input class="category form-check-input" type="checkbox" value="11" onChange={(e) => filterbycategoryfun(data, e)} />
                    <label class="form-check-label fs-14 text-dark" for="flexCheckDefault">
                      {data.name}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <hr />
          </div>
        </div>

        <div class="col-12 col-md-7 col-lg-9">
          <div class="row g-3">
            <div class="mb-5">
              <div class="row">
                {categoryServices.length > 0 ? (
                  <>
                    {categoryServices?.map((data, index) => (
                      <div class="col-md-12 mb-2" key={index}>
                        <div class="row p-2 bg-white border rounded">
                          <div class="col-md-3 mt-1">
                            <img class="img-fluid img-responsive rounded product-image" src={data?.image} />
                          </div>
                          <div class="col-md-6 mt-1">
                            <h5>{data.name}</h5>

                            <div class="mt-1 mb-1">
                              {/* <span class="dot"></span> */}
                              <span dangerouslySetInnerHTML={{ __html: data.description }}></span>
                            </div>
                            <div class="mt-1 mb-1">
                              <span>
                                Service Mode -
                                <span style={{ textTransform: "uppercase", color: "#000" }}>
                                  {" "}
                                  <b>{data?.service_mode}</b>
                                </span>
                              </span>
                            </div>
                            <div class="mt-1 mb-1">
                              <span>Estimate Time - {data?.estimate_time} min</span>
                            </div>
                            {data.consult_charge != 0 && (
                              <div class="mt-1 mb-1" style={{ display: "flex", alignItems: "center" }}>
                                <button
                                  class="btn btn-outline-primary btn-sm mt-2"
                                  onClick={() => {
                                    if (!auth) {
                                      toast.warn("Please Login");
                                      return;
                                    }
                                    navigate("/checkout2", { state: data });
                                  }}
                                  style={{ backgroundColor: "black", border: "black", color: "#fff" }}
                                  type="button"
                                >
                                  Consult ₹{data?.consult_charge} Now
                                </button>
                              </div>
                            )}
                            {/* {data.consult_required ? (
                              <div class="mt-1 mb-1 spec-1">
                                <span class="dot"></span>
                                <span>Consultation fee - ₹{data?.consult_fee}</span>
                              </div>
                            ) : (
                              ""
                            )} */}

                            {/* <button
                          class="btn btn-primary btn-sm mt-2"
                          style={{ backgroundColor: "black", border: "black" }}
                          type="button"
                          onClick={() => navigate(`/service-detail/${data._id}`}
                        >
                          View More
                        </button> */}
                          </div>
                          <div class="align-items-center align-content-center col-md-3 border-left mt-1">
                            {data.service_mode === "both" && (
                              <div class="d-flex flex-column mt-4">
                                <button
                                  class="btn btn-primary btn-sm"
                                  style={{ backgroundColor: "black", border: "black" }}
                                  type="button"
                                  onClick={() => {
                                    setservice_id(data._id);
                                    setRequestCallbackpopup(true);
                                  }}
                                >
                                  OnSite Service
                                </button>
                                <button
                                  class="btn btn-outline-primary btn-sm mt-2"
                                  onClick={() => {
                                    if (!auth) {
                                      toast.warn("Please Login");
                                      return;
                                    }
                                    navigate("/checkout", { state: data });
                                  }}
                                  style={{ backgroundColor: "black", border: "black", color: "#fff" }}
                                  type="button"
                                >
                                  Online Service
                                </button>
                              </div>
                            )}
                            {data.service_mode === "onsite" && (
                              <div class="d-flex flex-column mt-4">
                                <button
                                  class="btn btn-primary btn-sm"
                                  style={{ backgroundColor: "black", border: "black" }}
                                  type="button"
                                  onClick={() => {
                                    setservice_id(data._id);
                                    setRequestCallbackpopup(true);
                                  }}
                                >
                                  OnSite Service
                                </button>
                              </div>
                            )}
                            {data.service_mode === "online" && (
                              <div class="d-flex flex-column mt-4">
                                <button
                                  class="btn btn-outline-primary btn-sm mt-2"
                                  onClick={() => {
                                    if (!auth) {
                                      toast.warn("Please Login");
                                      return;
                                    }
                                    navigate("/checkout", { state: data });
                                  }}
                                  style={{ backgroundColor: "black", border: "black", color: "#fff" }}
                                  type="button"
                                >
                                  Online Service
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <DataNotFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RequestCallback open={RequestCallbackpopup} service_id={service_id} onclose={() => setRequestCallbackpopup(!RequestCallbackpopup)} />
      <Loder loading={isloading} />
    </div>
  );
};

export default SubCategory;
