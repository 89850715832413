import React, { useEffect, useState } from "react";
import DataNotFound from "../../Common/DataNotFound";
import { Avatar } from "@material-ui/core";
import "./Notify.css";
import { customer_reviews_api, notifications_vendor_api } from "../api/vender";
import { toast } from "react-toastify";
import { AiFillStar } from "react-icons/ai";
import ReactStars from "react-rating-stars-component";
const RatingReviews = () => {
  const [RatingReviewsArray, setRatingReviewsArray] = useState([]);
  const [isloading, setisloading] = useState(false);
  useEffect(() => {
    getallreviesfun();
  }, []);
  const getallreviesfun = async () => {
    setisloading(true);

    try {
      const res = await customer_reviews_api();
      if (res.data.status) {
        console.log(res);
        setRatingReviewsArray(res.data.data.reviews);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };
  return (
    <div>
      <section class="section">
        <div class="container">
          <h3 class="m-b-50 heading-line">
            Rating & reviews ({RatingReviewsArray?.length}) <i class="fa fa-bell text-muted"></i>
          </h3>

          <div class="notification-ui_dd-content">
            {RatingReviewsArray?.length > 0 ? (
              RatingReviewsArray.map((data, index) => (
                <div class={`notification-list  ${data.opened ? "notification-list--unread" : ""}`}>
                  <div class="notification-list_content">
                    <div class="notification-list_img">
                      <Avatar
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "6px",
                        }}
                      />
                    </div>
                    <div class="notification-list_detail">
                      <p>{data?.review}</p>
                      <p class="text-muted">
                        <ReactStars
                          style={{ justifyContent: "center" }}
                          count={5}
                          value={data?.rating}
                          size={20}
                          activeColor="#FFB450"
                          color="#ABABAB"
                          isHalf={true}
                          edit={false}
                          classNames="star_class"
                          emptyIcon={<AiFillStar />}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<AiFillStar />}
                        />
                      </p>
                      <p class="text-muted">
                        <small>{data?.created_at}</small>
                      </p>
                    </div>
                  </div>
                  <div class="notification-list_feature-img" style={{ cursor: "pointer" }}>
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </div>
                </div>
              ))
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RatingReviews;
