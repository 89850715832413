import React from "react";
import coupon from "../../assets/Icons/coupan.png";
import Expand from "react-expand-animated";
import { useEffect } from "react";
import { apply_coupon_api, get_coupons_api } from "../api/offer";
import { toast } from "react-toastify";
import { useState } from "react";
import Loder from "../Loder/Loder";

const CouponsDiscount = ({ serviceid, finalprice, onsubmit }) => {
  const [expandboxcoupan, setexpandboxcoupan] = useState(false);
  const [allcouponsArry, setallcouponsArry] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [discountprice, setdiscountprice] = useState("");

  useEffect(() => {
    coupons_get_fun();
  }, []);
  const coupons_get_fun = async () => {
    setisloading(true);
    try {
      const res = await get_coupons_api();
      if (res.data.status) {
        console.log(res);
        setallcouponsArry(res.data.data.coupons);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const apply_coupon_function = async (data) => {
    setisloading(true);
    const temp = {
      service_id: serviceid,
      coupon_code: data.code,
    };
    try {
      const res = await apply_coupon_api(temp);
      if (res.status) {
        setisloading(false);
        toast.success(res.data.message);

        if (data.amount_type === "fix") {
          const total_amount = data.offer_amount;
          const calback = {
            discount_amount: total_amount,
            code: data.code,
            gst: Number((finalprice.paying_amount - total_amount) * 18) / 100,
          };
          onsubmit(calback);
          setdiscountprice(total_amount);
        }

        if (data.amount_type === "percent") {
          const discount_amount = Math.trunc(finalprice.paying_amount) * data.offer_amount * 0.01;
          const calback = {
            discount_amount: discount_amount,
            code: data.code,
            gst: Number((finalprice.paying_amount - discount_amount) * 18) / 100,
          };
          onsubmit(calback);
          setdiscountprice(discount_amount);
        }
        setexpandboxcoupan(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      //   toast.error("Error Notification !");
      setisloading(false);
    }
  };
  return (
    <>
      <section className="discount_offer mb-3">
        <div className="row">
          <div className="col-md-12" style={{ cursor: "pointer" }}>
            <div className="form__radios">
              <div className="form__radio" onClick={() => setexpandboxcoupan(!expandboxcoupan)}>
                <label for="visa">
                  <img src={coupon} style={{ width: "7%", marginRight: "7px" }} alt="gpay" />
                  Apply Promocode
                </label>
                <span className="" style={{ color: "blue" }}>
                  Here
                </span>
              </div>
            </div>
            <div>{discountprice ? <span>You will get INR {discountprice} Extra on the recharge</span> : ""}</div>
            <Expand open={expandboxcoupan}>
              {allcouponsArry.map((data, index) => (
                <div className="Card_shadow mt-3 p-3">
                  <h5>{data?.title}</h5>
                  <p>
                    Get {data.amount_type == "fix" ? "RS" : ""} {data?.offer_amount}
                    {data.amount_type == "fix" ? "" : "%"} off with this code
                  </p>
                  <div className="coupon_code_list">
                    <div>
                      <p className="coupon_code_style">{data?.coupan_code}</p>
                    </div>
                    <div>
                      <button className="_2KpZ6l RLM7ES _3AWRsL mb-2" onClick={() => apply_coupon_function(data)}>
                        Apply Here
                      </button>
                    </div>
                  </div>
                  <div>
                    <p className="amount_save" style={{ fontSize: "14px" }}>
                      You will save {data.amount_type == "fix" ? "RS" : ""} {data?.offer_amount}
                      {data.amount_type == "fix" ? "" : "%"} off with this code
                    </p>
                  </div>
                </div>
              ))}
            </Expand>
          </div>
        </div>
      </section>
      <Loder loading={isloading} />
    </>
  );
};

export default CouponsDiscount;
