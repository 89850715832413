import React, { useEffect, useState } from "react";
import s from "./home.module.css";
import OwlCarousel from "react-owl-carousel3";
import { fetch_services_by_categoryId } from "../api/Category";
import { useNavigate } from "react-router-dom";

const RecentServices = () => {
  const [CategoryServices, setCategoryServices] = useState([]);
  const navigate = useNavigate();
  const options = {
    loop: false,
    nav: true,
    autoplay: true,
    rewind: false,
    mouseDrag: true,
    margin: 10,
    center: false,
    callback: true,
    slidetransition: "linear",
    autoplaySpeed: 4500,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      768: {
        items: 4,
      },
      992: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    fetchCategoryServices();
  }, []);

  async function fetchCategoryServices() {
    try {
      let res = await fetch_services_by_categoryId("6523c624ea87d1fefa1db87a");
      if (res.data.status) {
        setCategoryServices(res.data.data);
      }
    } catch (error) {
      console.log("error");
    }
  }
  return (
    <div>
      <div id="salonHome" class="padding-top padding-bottom mt-5">
        <div class="container">
          <div class="row section-title">
            <h3 class="wow fadeInDown animated" style={{ paddingBlock: "0.5rem" }}>
              Factory Automation Services
            </h3>

            <div className="">
              {CategoryServices?.length > 0 && (
                <OwlCarousel callback={true} className="client-wrap owl-carousel owl-theme" {...options}>
                  {CategoryServices.map((data, i) => {
                    return (
                      <div className={s?.services} style={{ width: "100%" }} onClick={() => navigate(`/service-detail/${data?._id}`)}>
                        <img src={data?.image} style={{ height: "12rem" }} />
                        <div className={s["service-title-des"]}>
                          <h5>{data.name}</h5>
                          <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentServices;
