export const initialState = null;

export const reducer = (state, action) => {
  if (action.type == "USER") {
    return action.payload;
  } else if (action.type == "LOGIN_OPEN_POPUP") {
    return { ...state, loginPopup: true };
  } else if (action.type == "LOGIN_CLOSE_POPUP") {
    return { ...state, loginPopup: false };
  } else if (action.type == "OTP_VERIFY_OPEN_POPUP") {
    return { ...state, otpPopup: true };
  } else if (action.type == "OTP_VERIFY_CLOSE_POPUP") {
    return { ...state, otpPopup: false };
  } else if (action.type == "REGISTER_OPEN_POPUP") {
    return { ...state, registerPopup: true };
  } else if (action.type == "REGISTER_CLOSE_POPUP") {
    return { ...state, registerPopup: false };
  } else if (action.type == "LODING_ON_STATE") {
    return { ...state, loading: true };
  } else if (action.type == "LODING_OFF_STATE") {
    return { ...state, loading: false };
  }
  return state;
};
