import React from "react";
import NavbarTop from "../Common/Header/NavbarTop";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import WhyChoose from "../Components/Home/WhyChoose";
import TopCategory from "../Components/Home/TopCategory";
import RecentServices from "../Components/Home/RecentServices";
import Topservices from "../Components/Home/Topservices";
import ServicesDetails from "../Components/Services/ServicesDetails";

const ServiceDetailPage = () => {
  return (
    <>
      <NavbarTop />
      <Header />
      <ServicesDetails />
      <RecentServices />
      <TopCategory />
      <WhyChoose />
      <Footer />
    </>
  );
};

export default ServiceDetailPage;
