import React, { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import Header2 from "../../Common/Header/Header2";
import s from "./vender.module.css";
import { MdVerified } from "react-icons/md";
import DataNotFound from "../../Common/DataNotFound";
import { vendor_upload_documents_api, vendorprofile_api } from "../api/vender";
import { toast } from "react-toastify";
import dummy from "./../../assets/Icons/default.jpg";
const KycStatus = () => {
  const [isloading, setisloading] = useState(false);
  const [kycdocuments, setkycdocuments] = useState([
    {
      name: "gst_certificate",
      verified: false,
      file: "",
    },
    {
      name: "company_pan",
      verified: false,
      file: "",
    },
    {
      name: "aadhar_card",
      verified: false,
      file: "",
    },
    {
      name: "msme_certificate",
      verified: false,
      file: "",
    },
    {
      name: "cancelled_cheque",
      verified: false,
      file: "",
    },
    {
      name: "other_doc",
      verified: false,
      file: "",
    },
  ]);

  const documentverfy_img = async (e, data, index) => {
    setisloading(true);
    const fd = new FormData();
    if (data.name === "gst_certificate") {
      fd.append("gst_certificate", e.target.files[0]);
    }
    if (data.name === "company_pan") {
      fd.append("company_pan", e.target.files[0]);
    }
    if (data.name === "aadhar_card") {
      fd.append("aadhar_card", e.target.files[0]);
    }
    if (data.name === "msme_certificate") {
      fd.append("msme_certificate", e.target.files[0]);
    }
    if (data.name === "cancelled_cheque") {
      fd.append("cancelled_cheque", e.target.files[0]);
    }
    if (data.name === "other_doc") {
      fd.append("other_doc", e.target.files[0]);
    }

    try {
      const res = await vendor_upload_documents_api(data, fd);

      if (res.data.status) {
        toast.success(res.data.message);
        vendorprofile();
      } else {
        console.log("data response error:::", res);
        toast.error(res.data.message);
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error(error);
      setisloading(false);
    }
  };

  useEffect(() => {
    vendorprofile();
  }, []);
  const vendorprofile = async () => {
    setisloading(true);
    try {
      const res = await vendorprofile_api();
      if (res.data.status) {
        const { documents } = res?.data?.data?.vendor;
        const dd = documents.map((data, index) => {
          return {
            name: data.name,
            verified: data.verified,
            file: data.file,
          };
        });

        // Create a copy of your current state
        const updatedKycDocuments = [...kycdocuments];

        // Loop through the API response and update the local state
        dd.forEach((apiDoc) => {
          const existingDocIndex = updatedKycDocuments.findIndex((localDoc) => localDoc.name === apiDoc.name);

          if (existingDocIndex !== -1) {
            // If the document with the same name exists in the local state, update it
            updatedKycDocuments[existingDocIndex] = apiDoc;
          } else {
            // If the document doesn't exist, add it to the local state
            updatedKycDocuments.push(apiDoc);
          }
        });

        // Update the state with the merged array
        setkycdocuments(updatedKycDocuments);

        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  const filename = (data) => {
    if (data.name == "gst_certificate") return "GST Certificate";
    if (data.name == "company_pan") return "Company Pan";
    if (data.name == "aadhar_card") return "Aadhar Card";
    if (data.name == "msme_certificate") return "MSME Certificate";
    if (data.name == "cancelled_cheque") return "Cancelled Cheque";
    if (data.name == "other_doc") return "Other Doc";
  };

  // function handleFile(url) {
  //   const fileExtension = url.split(".").pop();
  //   console.log(fileExtension); // Output: pdf

  //   if (fileExtension === "pdf") {
  //   } else {
  //   }
  // }
  return (
    <div>
      <Header2 />
      <div classNameName={s["main"]}>
        <div className="container">
          <section className={s["signup"]}>
            <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "1rem" }}>
              <h4 className={s["form-title"]} style={{ textAlign: "center", paddingBlock: "1rem" }}>
                KYC Status{" "}
                {/* <span style={{ color: "blue" }}>
                  <MdVerified size={20} />
                </span> */}
              </h4>
              <div className={["booking-list"]}>
                {kycdocuments?.length > 0 ? (
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Image</th>
                        <th scope="col">DOCUMENT TYPE</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kycdocuments?.map((data, index) => (
                        <tr key={index}>
                          {data?.file?.split(".")?.pop() == "pdf" ? (
                            <td>
                              <embed src={data?.file ? data?.file : dummy} style={{ cursor: "pointer", height: "40px", width: "80px" }} />
                              <br />
                              <a style={{ cursor: "pointer", color: "blue" }} href={data?.file} target="_blank">
                                View PDF
                              </a>
                            </td>
                          ) : (
                            <td>
                              <img src={data?.file ? data?.file : dummy} style={{ height: "80px", width: "80px" }} />
                            </td>
                          )}

                          <td>{filename(data)}</td>
                          <td>
                            {data.verified ? (
                              <span style={{ color: "blue" }}>
                                <MdVerified size={20} />
                              </span>
                            ) : (
                              <span>
                                <MdVerified size={20} />
                              </span>
                            )}
                          </td>
                          <td>
                            <div>
                              <label htmlFor={`contained-button-file${index}`} className="button_outer" style={{ cursor: "pointer" }}>
                                Upload Image
                              </label>
                              <input
                                type="file"
                                accept=".pdf, .jpeg, .jpg, .png"
                                style={{ display: "none" }}
                                id={`contained-button-file${index}`}
                                onChange={(e) => documentverfy_img(e, data, index)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <DataNotFound />
                )}
              </div>
            </Card>
          </section>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="footer-copyright-wrapper">
          <p class="footer-copyright-text">
            <a class="footer-copyright-link" href="#" target="_self">
              ©2023. | CLOUDGEM P.V.T L.T.D. All Rights Reserved | All rights reserved.{" "}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default KycStatus;
