import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const BreadcrumbSection = ({ tittle }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container mt-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              <a>Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {tittle}
            </li>
          </ol>
        </nav>
      </div>
    </>
  );
};

export default BreadcrumbSection;
