import React, { useEffect } from "react";
import InvoiceList from "../Components/Checkout/InvoiceList";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import ConsultList from "../Components/Checkout/ConsultList";

const ConsultListPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <ConsultList />
      <Footer />
    </div>
  );
};

export default ConsultListPage;
