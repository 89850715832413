import React from "react";
import VenderHome from "../Components/Vender/VenderHome";
import Header2 from "../Common/Header/Header2";
import VenderHome2 from "../Components/Vender/VenderHome2";

const VenderHomePage = () => {
  return (
    <>
      <Header2 />
      <VenderHome2 />
      <div class="footer-copyright">
        <div class="footer-copyright-wrapper">
          <p class="footer-copyright-text">
            <a class="footer-copyright-link" href="#" target="_self">
              ©2023. | CLOUDGEM P.V.T L.T.D. All Rights Reserved | All rights reserved.{" "}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default VenderHomePage;
