import React from "react";
import s from "./MoreiBuzoo.module.css";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
const TermsofService = () => {
  return (
    <>
      <div className={s["termsof-container"]}>
        <BreadcrumbSection tittle="Terms of Service" />
        <div className={s["termsof-title"]}>
          <h1>Terms & Conditions</h1>
        </div>
        <div className="termsof-layout pb-5">
          <p>
            ClOUDGEM PRIVATE LIMITED is maintainingthe website iBuzoo-eServices .co.in, including all information and services available from this site, the
            useracceptance of all terms, conditions, policies and notices stated here.
          </p>
          <p></p>
          <p>
            By visiting iBuzoo-eServices .co.in portal and/ or taking any service from this portal, you engage in our “Service” and agree to accept the
            following terms and conditions (“Terms of Service”) including those additional terms and conditions and policies referenced herein and/or available
            by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers,
            merchants, and/ or contributors of content.
          </p>
          <p>
            Any new product, service or feature which are added shall also be subject to the Terms of Service. Anyone can review the most current version of the
            Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates
            and/or changes to our website. It is users’ responsibility to check this page periodically.{" "}
          </p>
          <h4>GENERAL</h4>

          <p>iBuzoo-eServices is a online platform of the industrial services for the manufacturing industry</p>
          <p>
            By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you
            are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this
            site.
          </p>
          <p>
            You may not use our services for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction
            (including but not limited to copyright laws).
          </p>
          <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
          <p>A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>
          <p>
            You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any
            contact on the website through which the service is provided, without express written permission by us.
          </p>
          <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
          <h4>ACCURACY, PRODUCT/PROJECT COMPLETINESS </h4>
          <p>
            We are not responsible if information made available on this site is not accurate, complete or current and relevant to your requirement. The
            material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without
            technical consulting primary, more accurate, more complete or more timely sources of information.
          </p>
          <h4>CHANGES IN THE SERVICE AND PRICES</h4>
          <p>Prices for the products listing on the portal are subject to change without any prior notice.</p>
          <p>We reserve the right at any time to modify or discontinue the Service without notice at any time.</p>
          <p>We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
          <h4>PRODUCTS OR SERVICES </h4>
          <p>
            Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are
            subject to return or exchange only according to our Return Policy.
          </p>
          <p>We have made every effort to display as accurately as possible the images of our products that appear at the portal.</p>
          <p>
            We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may
            exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All
            descriptions of products or product pricing are subject to change at anytime without notice
          </p>
          <h4>ACCURACY OF BILLING AND ACCOUNT INFORMATION</h4>
          <p>
            You agree to provide current, complete and accurate purchase and account information for all purchases. You agree to promptly update your account
            and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions.
          </p>
          <h4>PRODUCT INFORMATION LINKS/THIRD PARTY SITES</h4>
          <p>Some content, products and services on our portal may include materials from third-parties.</p>
          <p>
            Third-party links on this site may direct you to third-party websites These other sites and parties are not under our control, and we are not
            responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites, nor are we responsible for
            errors or omissions in any references to other parties or their products and services.
          </p>
          <p>
            We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in
            connection with any third-party websites. Please review carefully the third-party’s policies and practices.
          </p>
          <h4>PERSONAL INFORMATION</h4>
          <p>Your submission of personal information on the portal is governed by our Privacy Policy. </p>
          <h4>ERRORS, INACCURACIES AND OMISSIONS</h4>
          <p>
            Sometimes there may be information and the Service on website that contains typographical errors, inaccuracies or omissions that may relate to
            product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any
            errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website
            is inaccurate at any time without prior notice{" "}
          </p>
          <h4>TERMINATION</h4>
          <p>
            The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all
            purposes.
          </p>
          <p>
            These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by
            notifying us that you no longer wish to use our Services, or when you cease using our site.
          </p>
          <p>
            If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may
            terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination;
            and/or accordingly may deny you access to our Services.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsofService;
