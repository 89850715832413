import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/Logo/LOGO.png";
import { AiOutlineArrowRight, AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { GiHamburgerMenu } from "react-icons/gi";
import Cookies from "js-cookie";
import { get_profile } from "../../Components/api/profile";
import s from "./Header.module.css";
import { BiSolidUser } from "react-icons/bi";

const Header = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(UserContext);
  const lenght = state?.user_profile?.data?.cart?.length;
  const name = state?.user_profile?.data?.first_name;
  const profile_image = state?.user_profile?.data?.profile_image;
  const auth = Cookies.get("auth");
  useEffect(() => {
    if (auth) {
      get_profile_fun();
    }
  }, []);

  const get_profile_fun = async () => {
    try {
      const res = await get_profile();
      if (res.data.status) {
        dispatch({
          type: "USER",
          payload: {
            ...state,
            user_profile: res.data,
          },
        });
      }
    } catch (error) {
      console.log("data response error:::", error);
    }
  };
  return (
    <div>
      <nav class="navbar navbar-expand-lg bg-white px-4 shadow-sm">
        <div class="container flex-nowrap">
          <a class="navbar-brand text-white" onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            <img src={Logo} alt="" class="img-fluid" style={{ width: "45%" }} />
          </a>

          <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="">
              <GiHamburgerMenu fontSize={"25px"} />
            </span>
          </button>
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" style={{ width: "300px" }}>
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                Hi Welcome iBuzoo
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body align-items-center">
              <ul class="navbar-nav  mx-auto mb-2 mb-lg-0 align-items-lg-center">
                <li class="nav-item mx-1" style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                  <a class="nav-link text-dark active">Home</a>
                </li>

                {/* <li class="nav-item mx-1">
                  <a
                    class="nav-link text-dark"
                    // onClick={() => navigate("/service")}
                    style={{ cursor: "pointer" }}
                  >
                    Services
                  </a>
                </li> */}

                <li class="nav-item mx-1 ">
                  <a class="nav-link text-dark" style={{ cursor: "pointer" }} onClick={() => navigate("/register")}>
                    <span className={s.register_as_profession_btn}>Register as a Services Partner</span>
                  </a>
                </li>
                <li class="nav-item mx-1 " onClick={() => navigate("/contact")} style={{ cursor: "pointer" }}>
                  <a class="nav-link text-dark ">Help</a>
                </li>
              </ul>

              <div class="profile mx-lg-3 my-2 my-lg-0 nav-item dropdown">
                {!auth || undefined ? (
                  <div
                    className="login_btn align-self-center"
                    onClick={() =>
                      dispatch({
                        type: "LOGIN_OPEN_POPUP",
                      })
                    }
                  >
                    <div id="loginButton" className="login_btn_header">
                      <BiSolidUser />
                      Login
                    </div>
                  </div>
                ) : (
                  <a href="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span style={{ paddingRight: "4px" }}>{name}</span>
                    {!profile_image ? (
                      <img src={`https://eu.ui-avatars.com/api/?rounded=true&name=${name}&size=350?`} alt="" srcset="" style={{ height: "50px" }} />
                    ) : (
                      <img src={profile_image} alt="" srcset="" style={{ height: "50px", width: "50px", borderRadius: "50%" }} />
                    )}
                  </a>
                )}
                {auth && (
                  <ul class="dropdown-menu">
                    <li style={{ cursor: "pointer" }} onClick={() => navigate("/profile")}>
                      <a class="dropdown-item">Profile</a>
                    </li>
                    <li style={{ cursor: "pointer" }} onClick={() => navigate("/booking")}>
                      <a class="dropdown-item">Booking List</a>
                    </li>
                    <li style={{ cursor: "pointer" }} onClick={() => navigate("/invoice")}>
                      <a class="dropdown-item">Invoice List</a>
                    </li>
                    <li style={{ cursor: "pointer" }} onClick={() => navigate("/consult")}>
                      <a class="dropdown-item">Consult List</a>
                    </li>
                    <li style={{ cursor: "pointer" }}>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          Cookies.remove("auth");
                          Cookies.remove("accessToken");
                          navigate("/");
                          dispatch({
                            type: "USER",
                            payload: {
                              ...state,
                              userdetails: {},
                            },
                          });
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                )}
              </div>
              {/* {auth && (
                <>
                  {lenght > 0 && (
                    <a class="btn bg-dark text-white text-nowrap" onClick={() => navigate("/cart")} style={{ cursor: "pointer" }}>
                      <div class="cart">
                        <span class="count">{lenght}</span>
                        <i class="material-icons">
                          {" "}
                          <AiOutlineShoppingCart size={20} />
                        </i>
                      </div>
                    </a>
                  )}
                </>
              )} */}

              {/* <a class="btn bg-dark text-white text-nowrap">
                Book Now <AiOutlineArrowRight size={20} />
              </a> */}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
