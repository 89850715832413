import React, { useState } from "react";
//tab pannel
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AllocatedBookings from "./AllocatedBookings";
import { vendorprofile_api } from "../api/vender";
import { toast } from "react-toastify";
import { useEffect } from "react";
const VenderHome = () => {
  const [value, setValue] = useState(0);
  const [isloading, setisloading] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    vendorprofile();
  }, []);
  const vendorprofile = async () => {
    setisloading(true);
    try {
      const res = await vendorprofile_api();
      if (res.data.status) {
        console.log(res);
        setisloading(false);
        return;
      }
      setisloading(false);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
      setisloading(false);
    }
  };

  return (
    <>
      <div className="container mt-5 mb-5">
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Home" {...a11yProps(0)} />
            <Tab label="Bookings" {...a11yProps(1)} />
            <Tab label="Finance" {...a11yProps(2)} />
            <Tab label="Help" {...a11yProps(3)} />
            <Tab label="Rating and Reviews" {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <AllocatedBookings />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AllocatedBookings />
          </TabPanel>
          <TabPanel value={value} index={2}>
            Finance
          </TabPanel>
          <TabPanel value={value} index={3}>
            Help
          </TabPanel>
          <TabPanel value={value} index={4}>
            Rating and Reviews
          </TabPanel>
        </Box>
      </div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ flexGrow: "1" }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `verticle-tab-${index}`,
    "aria-controls": `verticle-tabpanel-${index}`,
  };
}

export default VenderHome;
