import React, { useEffect } from "react";
import Header from "../Common/Header/Header";
import TermsofService from "../Components/MoreiBuzoo/TermsofService";
import Footer from "../Common/Footer/Footer";
const TermsofServicePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header />
      <TermsofService />
      <Footer />
    </>
  );
};

export default TermsofServicePage;
