import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import s from "./DialogBox.module.css";
import Custombutton from "../../Common/Button/CustomButton";
import Logo from "../../assets/Logo/LOGO.png";
import { toast } from "react-toastify";
import { request_callback_api, user_review_api } from "../api/checkout";
import ReactStars from "react-rating-stars-component";

const UserRating = ({ open, onclose, cancelbookingid }) => {
  const [Rating, setRating] = useState("");
  const [review, setreview] = useState("");

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const submitRating = async () => {
    let temp = {
      booking: cancelbookingid,
      rating: Rating,
      review: review,
    };
    try {
      const res = await user_review_api(temp);
      if (res.data.status) {
        toast.success(res.data.message);
        onclose();
        setRating("");
        setreview("");
        return;
      }
      onclose();
      setRating("");
      setreview("");
      toast.warn(res.data.message);
    } catch (error) {
      console.log("data response error:::", error);
      toast.error("Error Notification !");
    }
  };

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true} onClose={() => onclose()}>
        <DialogTitle className="dialog_title" style={{ padding: "0rem" }}>
          <div className={s["logo-dialogbox"]}>
            <img src={Logo} alt="logo" />
          </div>
        </DialogTitle>
        <DialogContent className="sp_states_popup">
          <div>
            <h4 style={{ textAlign: "center" }}>Share more about your experience</h4>
          </div>

          <div className={s["login-form-container"]}>
            <div className="padding_desktop_view d-flex justify-content-center">
              <ReactStars count={5} onChange={ratingChanged} size={40} color2={"#ffd700"} />
            </div>
            <label>Enter your Review</label>
            <div className="">
              <div>
                <textarea
                  rows={3}
                  type="text"
                  placeholder="Share more about your experience"
                  value={review}
                  onChange={(e) => setreview(e.target.value)}
                  spellChec={true}
                  autoFocus="true"
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "15px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="submit-btn" onClick={() => submitRating()}>
            <Custombutton text="Submit" />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserRating;
